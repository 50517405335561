import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  DeleteIcon,
  Edit,
  NoUserIcon,
  SearchIcon,
} from "../../../assets/icons/Icons";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import AppLayout from "../../../components/applayout/AppLayout";
import useToken from "../../../hooks/useToken"; // Import the useToken hook
import interceptor from "../../../hooks/intercepter.js";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import { set } from "lodash";
import StatusToggle from "../users/StatusToggle";
import { useTranslation } from 'react-i18next';

const Packages = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDurModal, setShowDurModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const { t } = useTranslation("translation");

  const { token, removeToken } = useToken(); // Use the useToken hook

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = users.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(users.length / itemsPerPage);
  const [duration, setDuration] = useState();
  const [status, setStatus] = useState();
  /*   const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    setItemOffset(newOffset);
  }; */
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  console.log("currentItems", currentItems);
  useEffect(() => {
    fetchPacakages();
  }, []);
  const fetchPacakages = async () => {
    try {
      const response = await interceptor.axiosGet(
        "get",
        `admin/package?per_page=${100}`,
        token
      );
      if (response.status === 200) {
        setUsers(response.data.data.result);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      // Send delete request
      const response = await interceptor.axiosDelete(
        "DELETE",
        `admin/package/${id}`,
        null,
        token
      );

      // Check response status
      if (response.status === 200) {
        setLoading(false);
        toast.success("Package Deleted Successfully");
        // Update state to remove deleted user
        setUsers((users) => users.filter((user) => user.id !== id));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };
  const handleEdit = (user) => {
    navigate("/admin/edit-packages", { state: { user } });
  };

  const handleConfirmDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setDeleteId(null);
  };

  const handleModalConfirm = () => {
    handleDelete(deleteId);
    handleModalClose();
  };



  const handleModalDurClose = () => {
    setShowDurModal(false);
  };

  const handleModalDurConfirm = () => {
    try {
      interceptor.axiosPut(
        "put",
        "packages/content-duration",
        { content_duration: parseInt(duration) },
        token,

      ).then((res) => {
        debugger
        if (res.status === 200) {
          toast.success("Duration Updated Successfully");
          handleModalDurClose();
          fetchPacakages()
        }
      })

    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }

  };

  // const getStatus = async (user) => {
  //   await interceptor.axiosGet("GET", `content-validation-settings`, token
  //   ).then((res) => {
  //     if (res.status === 200) {
  //       setStatus(res?.data.content_validation_enabled)
  //     }
  //   })
  // };

  // const handleChangeStatus = async (user) => {
  //   try {
  //     const response = await interceptor.axiosPut("PUT", `content-validation-settings`,
  //       { content_validation_enabled: !status }, token
  //     );
  //     if (response.status === 200) {
  //       setStatus(response?.data.content_validation_enabled)
  //       toast.success("Status Updated Successfully");
  //     }
  //   } catch (error) {
  //     toast.error(error?.message);
  //     console.error("Update request failed:", error);
  //   }
  // };

  return (
    <AppLayout>
      <section className="mb-20 sm:mb-24">
        <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
        {t('packHeading')}
        </h5>
        <div className="flex flex-wrap items-center justify-start gap-3 mt-6 sm:mt-8">
          <Button
            size="sm"
            onClick={() => navigate("/admin/add-packages")}
            className="px-8 w-fit"
          >
            {t('addPackage')}
          </Button>
          <Button
            size="sm"
            onClick={() => setShowDurModal(true)}
            className="px-8 w-fit bg-dark"
          >
            {t('addContentDuration')}
          </Button>
          {/* <div className="px-8 w-fit valition"> 
            <span className="px-3 pt-1 font-medium text-sm" style={{verticalAlign:'middle'}}>Valider le contenu</span> 
            <StatusToggle
              isActive={status}
              onToggle={() => handleChangeStatus()}
            />
          </div> */}
        </div>
        <div className="mt-5 overflow-x-auto whitespace-nowrap">
          {loading ? (
            <p>Loading...</p>
          ) : users.length === 0 ? (
            <div className="flex flex-col items-center gap-5 mt-8 md:mt-12">
              <NoUserIcon
                width={150}
                height={96}
                className="w-24 h-16 sm:w-36 sm:h-24"
              />
              <p className="text-lg font-medium text-center">
                {" "}
                Aucun package trouvé, veuillez ajouter des packages
              </p>
              <Button
                size="sm"
                className="w-fit px-9"
                onClick={() => navigate("/admin/add-packages")}
              >
                Ajouter un paquet
              </Button>
            </div>
          ) : (
            <div className="w-full overflow-auto">
              <Table className="w-full overflow-hidden border border-light-gray rounded-xl">
                <TableHeader>
                  <TableRow className="border-none bg-background">
                    <TableHead className="text-sm font-medium">
                     {t('packageNane')}
                    </TableHead>
                    <TableHead className="text-sm font-medium">{t('price')}</TableHead>
                    <TableHead className="pr-8">{t('duration')}</TableHead>
                    <TableHead className="pr-8">{t('frequency')}</TableHead>
                    <TableHead className="pr-8">{t('hours')}</TableHead>
                    <TableHead className="pr-8 text-right">{t('action')}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {currentItems.map((user) => (
                    <TableRow key={user.id} className="even:bg-background">
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.title}
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.price}
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.content_duration} sec
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.content_frequency ? user.content_frequency : "-"}
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.duration_in_hours ? user.duration_in_hours : "-"}
                      </TableCell>
                      <TableCell className="text-right py-2.5 flex items-center justify-end gap-2.5 h-14 pr-5">
                        <button
                          onClick={() => handleEdit(user)}
                          className="flex items-center justify-center w-6 h-6 rounded-md bg-blue"
                        >
                          <Edit stroke="white" width={14} height={14} />
                        </button>
                        <button
                          onClick={() => handleConfirmDelete(user.id)}
                          className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                        >
                          <DeleteIcon stroke="white" width={14} height={14} />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
        <div className="flex justify-center mt-6">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </div>
      </section>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-6 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">Confirm Deletion</h3>
            <p className="mb-4">
              Are you sure you want to delete this package?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleModalClose}
                className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleModalConfirm}
                className="px-4 py-2 text-white bg-red-600 rounded bg-red"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showDurModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-6 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">définir la durée</h3>
            <div className=" mb-5 ">
              <Input
                type="text"
                className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl"
                placeholder="Duration"
                name="duration"
                onChange={(e) => setDuration(e.target.value)}
              />
            </div>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleModalDurClose}
                className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleModalDurConfirm}
                className="px-4 py-2 text-white bg-red-600 rounded bg-red"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default Packages;
