import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import interceptor from "../../../../hooks/intercepter";

// Assets
import pkgImg1 from "../../../../assets/images/pkg-img1.png";
import pkgImg2 from "../../../../assets/images/pkg-img2.png";
import pkgImg3 from "../../../../assets/images/pkg-img3.png";

const packageImages = [pkgImg1, pkgImg2, pkgImg3];

const StepTwo = ({ onSelectedPackage, duration, seleted }) => {
  const { t } = useTranslation("translation");
  const { broadcasterId } = useParams();
  const [selectedPackage, setSelectedPackage] = useState(seleted || "");
  const [packages, setPackages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async () => {
    try {
      const response = await interceptor.axiosGet(
        "get",
        `admin/package/advertiser?admin=true&broadcaster_id=${broadcasterId}`
      );
      if (response.status === 200) {
        let fetchedPackages = response?.data?.data?.result;
        const freePackages = fetchedPackages.filter(pkg => pkg.is_free);
        fetchedPackages = fetchedPackages.filter(pkg => !pkg.is_free);
        fetchedPackages = [...freePackages, ...fetchedPackages];
        setPackages(fetchedPackages);
      }
    } catch (error) {
      console.error("Failed to fetch packages:", error);
    }
  };


  const handlePackageSelect = (value) => {
    setErrorMessage("");
    const pkg = packages.find((p) => p.id === value);
    if (duration > pkg.content_duration) {
      setErrorMessage(
        `Please select a video that is no longer than ${pkg.content_duration} seconds.`
      );
      return;
    }
    setSelectedPackage(value);
    onSelectedPackage(value);
  };

  const isDisabled = (value) => selectedPackage && selectedPackage !== value;

  const formatText = (value, singular, plural) =>
    value === 1 ? `${value} ${singular}` : `${value} ${plural}`;

  const formatDuration = (hours) => {
    debugger
    if (hours >= 720) {
      const months = (hours / 720).toFixed(1);
      return months === "1.0" ? ` ${parseInt(months)} ${t("1month")}` : `${months} ${t("months")}`;
    } else if (hours >= 24) {
      const days = Math.floor(hours / 24);
      return days === 1 ? `${days} ${t("1day")}` : `${days} ${t("days")}`;
    }
    return hours === 1 ? `${hours} ${t("1hour")}` : `${hours} ${t("hours")}`;
  };


  return (
    <section>
      <div className="md:container mx-auto px-5">
        <div className="lg:w-6/12 w-full mx-auto">
          <h4 className="md:text-3xl text-2xl text-dark font-bold text-center">
            {t("step2")}
          </h4>
          <form>
            <div className="flex flex-col gap-5 items-center justify-center mt-10 w-full">
              {packages.map((pkg, index) => (
                <label
                  key={pkg.id}
                  className={`cursor-pointer w-full transition-all duration-700 ${selectedPackage === pkg.id ? "scale-[1.05]" : ""
                    } ${isDisabled(pkg.id) ? "filter grayscale-[1]" : ""}`}
                >
                  <input
                    type="radio"
                    name="package"
                    value={pkg.id}
                    checked={selectedPackage === pkg.id}
                    onChange={() => handlePackageSelect(pkg.id)}
                    className="hidden"
                  />
                  <div className="flex items-start gap-1 w-full">
                    <div
                      className={`rounded-xl px-4 py-3 w-full ${index === 0
                        ? "bg-thin-blue border-2 border-dark-blue"
                        : index === 1
                          ? "bg-light-orange border-2 border-orange"
                          : index === 2
                            ? "bg-light-green border-2 border-green"
                            : "bg-light-red border-2 border-red"
                        }`}
                    >
                      <div className="flex items-center gap-4">
                        <img
                          src={packageImages[index] || pkgImg1}
                          width={77}
                          height={86}
                          className="block object-contain w-[6.25rem] h-[6.25rem]"
                          alt=""
                        />
                        <div className="flex flex-col">
                          <h6 className="sm:text-lg text-sm font-bold text-dark">
                            {pkg.title}
                          </h6>
                          <small className="text-xs font-semibold block mt-1 text-dark">
                            {pkg.content_frequency
                              ? formatText(pkg.content_frequency, t("frequency"), t("frequences"))
                              : formatText(pkg.duration_in_hours, t("1hour"), t("hours"))}
                          </small>
                          <p className="text-sm text-dark mt-3">
                            {pkg.content_frequency
                              ? pkg.content_frequency === 1
                                ? t("step2Detail4", {
                                  time: formatText(pkg.content_frequency, t("1time"), t("times")),
                                  duration: pkg.content_duration,
                                })
                                : t("step2Detail5", {
                                  time: formatText(pkg.content_frequency, t("1time"), t("times")),
                                  duration: pkg.content_duration,
                                })
                              : t("step2Detail3", {
                                hour: formatDuration(pkg.duration_in_hours),
                                duration: pkg.content_duration,
                              })}
                          </p>


                        </div>
                      </div>
                      <div>
                        <strong className="sm:text-base text-sm font-bold text-dark block text-right">
                          {pkg.is_free ? "Free" : `${pkg.price}€`}
                        </strong>
                      </div>
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </form>
          {errorMessage && <p className="text-red-500 mt-3">{errorMessage}</p>}
        </div>
      </div>
    </section>
  );
};

export default StepTwo;
