import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppLayout from "../../../components/applayout/AppLayout";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import useToken from "../../../hooks/useToken";
import interceptor, { baseUrl } from "../../../hooks/intercepter.js";
import Loader from "../../../components/ui/Loader";
import toast from "react-hot-toast";
import { PhoneNumberInput } from "../../../components/ui/phoneInput";
import { z } from "zod"; // Import Zod
import { useForm } from "react-hook-form"; // Import React Hook Form
import { zodResolver } from "@hookform/resolvers/zod"; // Import Zod Resolver
import { FormField } from "../../../components/ui/form"; // Import FormField component
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  DeleteIcon,
  Edit,
  NoUserIcon,
  SearchIcon,
  VideoIcon,
  ViewIcon,
} from "../../../assets/icons/Icons";

import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
// Define validation schema using Zod
const schema = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().min(1, "Email is required").email("Invalid email"),
  phone: z.string().min(1, "Phone is required!").regex(/^\+33\d{9}$|^\+[1-9]\d{10,12}$/, "Invalid phone number"),
  location_name: z.string().min(1, { message: "Location name is required" }),
  location_address: z.string().min(1, { message: "Location address is required" }),
  password: z.string().optional().refine((val) => !val || val.length >= 8, {
    message: "Password must have at least 8 characters",
  }),
  confirm_password: z.string().optional().refine((val) => !val || val.length >= 8, {
    message: "Confirmation Password must have at least 8 characters",
  }),
}).refine((data) => data.password === data.confirm_password, {
  path: ['confirm_password'],
  message: 'Password do not match'
})


const EditUser = () => {
  const { t } = useTranslation("translation");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useToken();
  const [user, setUser] = useState(location.state?.user || {});

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [frequency, setFrequency] = useState(""); // Default frequency
  const [currentItems, setcurrentItems] = useState([]); // Default frequency 
  const [allPackages, setAllPackages] = useState([]); // Default frequency 
  const [adminAdd, setAdminAdd] = useState([]); // Default frequency 
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [viewShowContentModal, setViewShowContentModal] = useState(null);
  const [type, setType] = useState(false);

  const [packageId, setPackageId] = useState("");
  const [packageTitle, setPackageTitle] = useState("");

  // const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      defaultValues: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
        location_name: "",
        location_address: "",
      },
    },
  });

  useEffect(() => {
    if (user) {
      setValue("first_name", user.first_name || "");
      setValue("last_name", user.last_name || "");
      setValue("email", user.email || "");
      setValue("phone", user.phone || "");
      setValue("location_name", user.location_name || "");
      setValue("location_address", user.location_address || "");
    }
    fetchPacakages();
    fetchAllPacakages();
    fetchAdminUpload()
  }, [user, setValue]);
  const [showModal, setShowModal] = useState(false);
  const fetchPacakages = async () => {
    try {
      const response = await interceptor.axiosGet(
        "get",
        `admin/package?admin=false&broadcaster_id=${user.id}&per_page=${100}`,
        token
      );
      if (response.status === 200) {
        setcurrentItems(response.data.data.result);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllPacakages = async () => {
    try {
      const response = await interceptor.axiosGet(
        "get",
        `admin/package?admin=true&broadcaster_id=${user.id}`,
        token
      );
      if (response.status === 200) {
        setAllPackages(response.data.data.result);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleEditUser = async (data) => {
    try {
      setLoading(true);
      const response = await interceptor.axiosPut(
        "PATCH",
        `users/${user.id}`,
        {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password ? data.password : "",
          confirm_password: data.confirm_password ? data.confirm_password : "",
          phone: data.phone ? data.phone : "",
          location_name: data.location_name,
          location_address: data.location_address,
        },
        token
      );
      if (response.status === 200) {
        console.log("User updated successfully");
        setLoading(false);
        toast.success("User Updated Successfully");
        navigate("/admin/users");
      } else {
        setLoading(false);
        toast.error(
          response.message.includes(")")
            ? response.message.split(")")[1]
            : response.message
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message || "Failed to update user");
      console.error("Failed to update user:", error);
    }
  };

  const handleSave = async () => {
    if (parseInt(frequency) < 1) {
      toast.error("Frequency must be greater than 0");
      return;
    }

    const data = {
      title: title,
      price: parseFloat(price),
      content_frequency: contentMode == 'frequency' ? parseInt(frequency) : '',
      duration_in_hours: contentMode == 'hours' ? parseInt(duration) : '',
      broadcaster_id: user.id,
      is_free: type
    };
    if (contentMode == 'frequency') {
      delete data.duration_in_hours;
    }
    if (contentMode == 'hours') {
      delete data.content_frequency;
    }

    try {
      setLoading(true);
      var response = null
      debugger
      if (!packageId) { response = await interceptor.axiosPost(`admin/package?broadcaster_id=${user.id}`, data, token); }
      else {
        response = await interceptor.axiosPatch("admin/package/" + packageId + `?broadcaster_id=${user.id}`, data, token);
      }
      setLoading(false);
      console.log(response.status);
      if (response.status === 200) {
        toast.success("Package Added Successfully");
        setShowModal(false);
        fetchPacakages();
      } else {
        toast.error("Failed to add package", response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add package");
      console.error("Error:", error);
    }
  };





  // add admin advertisement
  const fetchAdminUpload = async () => {
    try {
      const response = await interceptor.axiosGet(
        "get",
        `admin/content-upload?broadcaster_id=${user.id}`,
        token
      );
      if (response.status === 200) {
        setAdminAdd(response.data.data.contents);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };
  const [contentMode, setContentMode] = useState("frequency"); // Default frequency
  const handleViewContent = (content) => {
    setPackageTitle(content.title);
    setPackageId(content.package_id);
    setSelectedContent(content);
    setViewShowContentModal(true);
  };

  const handleFileChange = (e) => {
    debugger
    const selectedFile = e.target.files[0];
    console.log(selectedFile);

    if (selectedFile.type.startsWith("video/")) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(selectedFile);

      video.onloadedmetadata = () => {
        if (video.duration > 11) {
          alert("Please select a video that is no longer than 10 seconds.");
          setFile(null);
          setFileType("");
          setFileName("");
          setTitle("");
          setVideoThumbnail(null);
          setImagePreview(null);
          e.target.value = null;
        } else {
          setFile(selectedFile);
          setFileType(selectedFile.type);
          setFileName(selectedFile.name);
          setTitle(selectedFile.name);
          generateVideoThumbnail(selectedFile);
        }
      };
    } else if (selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
      setFileType(selectedFile.type);
      setFileName(selectedFile.name);
      setTitle(selectedFile.name);
      setImagePreview(URL.createObjectURL(selectedFile));
      setVideoThumbnail(null);
    }
  };
  const generateVideoThumbnail = (videoFile) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.currentTime = 1;

    video.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      setVideoThumbnail(thumbnailUrl);
      setImagePreview(null);
    };
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    if (!packageId) {
      alert("Please select Package first!");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("package_id", packageId);
    formData.append("content_type", file.type.split("/")[0]);
    formData.append("broadcaster_id", user.id);
    formData.append("title", packageTitle);

    try {
      const response = await interceptor.axiosPostFormData(
        "admin/content-upload",
        formData,
        token,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      if (response?.data?.status === true) {
        setLoading(false);
        toast.success("Upload successful");
      } else {
        setLoading(false);
        toast.error(response?.message);
      }
      fetchAdminUpload();
      setFile(null);
      setFileType("");
      setFileName("");
      setPackageTitle("");
      setVideoThumbnail(null);
      setImagePreview(null);
      setUploadProgress(0);
      setShowContentModal(false);
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error(error?.message);
    }
  };

  const handleEdit = (user) => {
    setTitle(user.title)
    setPrice(user.price)
    setFrequency(user.content_frequency)
    setDuration(user.duration_in_hours)
    setContentMode(user.content_frequency ? 'frequency' : 'hours')
    setPackageId(user.id)
    setType(user.is_free ? true : false)
    setShowModal(true)
  };
  //delete package
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDelModal] = useState(false);
  const handleConfirmDelete = (id) => {
    setDeleteId(id);
    setDelModal(true);
  };
  const handleModalConfirm = () => {
    console.log(deleteId);
    handleDelete(deleteId);
    handleModalClose();
  };

  const handleModalClose = () => {
    setDelModal(false);
    setDeleteId(null);
  }

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      // Send delete request
      const response = await interceptor.axiosDelete(
        "DELETE", `admin/package/${id}?broadcaster_id=${user.id}`, null, token
      );
      if (response.status === 200) {
        setLoading(false);
        toast.success("Package Deleted Successfully");
        debugger
        let filtepackege = allPackages.filter((packageItem) => packageItem.id !== id);
        setcurrentItems((prevPackages) =>
          prevPackages.filter((packageItem) => packageItem.id !== id)
        );
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  //delete Content
  const [deleteContentId, setDeleteContentId] = useState(null);
  const [deleteContentModal, setDelContentModal] = useState(false);

  const handleContentConfirmDelete = (id) => {
    setDeleteContentId(id);
    setDelContentModal(true);
  };
  const handleContentModalConfirm = () => {
    console.log(deleteId);
    handleContentDelete(deleteId);
    handleContentModalClose();
  };

  const handleContentModalClose = () => {
    setDelContentModal(false);
    setDeleteContentId(null);
  }

  const handleContentDelete = async (id) => {
    try {

      const response = await interceptor.axiosDelete(
        "DELETE",
        `admin/content-upload?content_id=${deleteContentId}`,
        { content_id: deleteContentId },
        token
      );
      if (response.status === 200) {
        toast.success("Content deleted successfully");
        fetchAdminUpload();
      } else {
        toast.error(response.message);

      }
    } catch (error) {
      console.error("Delete failed:", error);
      toast.error("Failed to delete content");
    }
  };

  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section className="sm:mb-24 mb-20 card p-4">
            <div className="sm:mt-8 mt-6">
              <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
                {t("edituser")}
              </h5>
              <form
                onSubmit={handleSubmit(handleEditUser)}
                className="flex flex-col gap-7 sm:mt-8 mt-6"
              >
                <div className="grid md:grid-cols-3 grid-cols-1 gap-7">
                  <div>
                    <FormField
                      control={control}
                      name="first_name"
                      render={({ field }) => (
                        <Fragment>
                          <Input
                            type="text"
                            {...field}
                            className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                            placeholder="First Name"
                          />
                          {errors.first_name && (
                            <p className="text-sm text-red">
                              {errors.first_name.message}
                            </p>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={control}
                      name="last_name"
                      render={({ field }) => (
                        <Fragment>
                          <Input
                            type="text"
                            {...field}
                            className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                            placeholder="Last Name"
                          />
                          {errors.last_name && (
                            <p className="text-sm text-red">
                              {errors.last_name.message}
                            </p>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <Fragment>
                          <Input
                            type="text"
                            {...field}
                            className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                            placeholder="Email"
                            disabled={true}
                          />
                          {errors.email && (
                            <p className="text-sm text-red">
                              {errors.email.message}
                            </p>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={control}
                      name="password"
                      render={({ field }) => (
                        <Fragment>
                          <Input
                            className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                            placeholder="New Passwrod.."
                            type="password"
                            autoComplete="off"
                            {...field}
                          />
                          {errors.password && (
                            <p className="text-sm text-red">
                              {errors.password.message}
                            </p>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={control}
                      name="confirm_password"
                      render={({ field }) => (
                        <Fragment>
                          <Input
                            className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                            placeholder="Confirm New Passwrod.."
                            type="password"
                            autoComplete="off"
                            {...field}
                          />
                          {errors.confirm_password && (
                            <p className="text-sm text-red">
                              {errors.confirm_password.message}
                            </p>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={control}
                      name="phone"
                      render={({ field }) => (
                        <Fragment>
                          <PhoneNumberInput
                            type="text"
                            name="phone"
                            {...field}
                            className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                            placeholder="Phone"
                          />
                          {errors.phone && (
                            <p className="text-sm text-red">
                              {errors.phone.message}
                            </p>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={control}
                      name="location_name"
                      render={({ field }) => (
                        <Fragment>
                          <Input
                            type="text"
                            {...field}
                            className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                            placeholder="Location Name"
                          />
                          {errors.location_name && (
                            <p className="text-sm text-red">
                              {errors.location_name.message}
                            </p>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={control}
                      name="location_address"
                      render={({ field }) => (
                        <Fragment>
                          <Input
                            type="text"
                            {...field}
                            className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                            placeholder="Location Address"
                          />
                          {errors.location_address && (
                            <p className="text-sm text-red">
                              {errors.location_address.message}
                            </p>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                </div>
                <div className="">
                  <Button size="sm" className="w-fit px-12 float-right" type="submit">
                   
                    {t("updateUser")}
                  </Button>
                </div>
              </form>
            </div>
          </section>
          <div
            className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showModal ? "" : "hidden"
              }`}
          >
            <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
              <div className="flex flex-col gap-5">
                <div className="bg-background rounded-xl sm:p-8 p-5">
                  <label className="text-base font-medium">Package</label>
                  <div className="flex flex-col gap-4 mt-5">
                    <div>
                      <Input
                        type="text"
                        className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div>
                      <Input
                        type="text"
                        className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                    <div>
                      <select
                        className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                        value={contentMode}
                        onChange={(e) => setContentMode(e.target.value)}
                      >
                        <option value="frequency">Content in Frequency</option>
                        <option value="hours">Content in Hours</option>
                      </select>
                    </div>
                    {contentMode == 'frequency' && <div>
                      <Input
                        type="number"
                        className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Content Frequency e.g 1,2,3.."
                        value={frequency}
                        min={1}
                        onChange={(e) => { setFrequency(e.target.value); setDuration(''); }}
                      />
                    </div>
                    }
                    {contentMode == 'hours' && <div>
                      <Input
                        type="number"
                        className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Content Repeat for hour e.g 1,2,3.."
                        value={duration}
                        min={1}
                        onChange={(e) => { setDuration(e.target.value); setFrequency('') }}
                      />
                    </div>
                    }
                    <div className="w-52 valition justify-between">
                      <span className="px-3 pt-1 font-bold text-sm" style={{ verticalAlign: 'middle' }}>Free</span>
                      <ReactSwitch
                        checked={type}
                        className="react-switch"
                        onChange={(e) => {
                          setType(e);
                        }}
                      />
                    </div>
                    <div className="text-right">
                      <Button
                        size="sm"
                        className="w-50 px-12 mr-5 bg-gray-300 text-gray-700"
                        // className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </Button>
                      <Button
                        size="sm"
                        className="w-50 px-12"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="sm:mb-24 mb-20 card ">
            <h5 className="text-lg font-semibold  border-light-gray p-4">
              {t("addCustomPackage")}
              <Button
                size="sm"
                className="w-fit px-12 mb-3 float-right border-b"
                onClick={() => setShowModal(true)}
              >
                {t("addPackage")}
              </Button>
            </h5>

            <div className="w-full overflow-auto">

              <Table className="w-full overflow-hidden border border-light-gray rounded-xl">
                <TableHeader>
                  <TableRow className="border-none bg-background">
                    <TableHead className="text-sm font-semibold">
                      Nom du paquet
                    </TableHead>
                    <TableHead className="text-sm font-semibold">Prix</TableHead>
                    <TableHead className="pr-8 font-semibold">Durée</TableHead>
                    <TableHead className="pr-8 font-semibold">Fréquence</TableHead>
                    <TableHead className="pr-8 font-semibold">Heures</TableHead>
                    <TableHead className="pr-8 text-right font-semibold">Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {currentItems.map((user) => (
                    <TableRow key={user.id} className="even:bg-background">
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.title}
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.is_free ? "Free" : user.price}
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.content_duration} sec
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.content_frequency ? user.content_frequency : "-"}
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user.duration_in_hours ? user.duration_in_hours : "-"}
                      </TableCell>

                      <TableCell className="text-right py-2.5 flex items-center justify-end gap-2.5 h-14 pr-5">
                        <button
                          onClick={() => handleEdit(user)}
                          className="flex items-center justify-center w-6 h-6 rounded-md bg-blue"
                        >
                          <Edit stroke="white" width={14} height={14} />
                        </button>
                        <button
                          onClick={() => handleConfirmDelete(user.id)}
                          className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                        >
                          <DeleteIcon stroke="white" width={14} height={14} />
                        </button>
                      </TableCell>
                    </TableRow>


                  ))}
                  {currentItems.length == 0 && <TableRow key={user.id} className="even:bg-background">
                    <TableCell className="text-sm font-normal py-2.5 h-auto" rowspan="6">
                      No Package Added Yet
                    </TableCell>
                  </TableRow>
                  }
                </TableBody>
              </Table>
            </div>
          </section>

          <section className="sm:mb-24 mb-20 card ">
            <h5 className="text-lg font-semibold  border-light-gray p-4">
              {t("addContent")}
              <Button
                size="sm"
                className="w-fit px-12 mb-3 float-right border-b"
                onClick={() => setShowContentModal(true)}
              >
               {t("addContent")}
              </Button>
            </h5>

            <div className="w-full overflow-auto">

              <Table className="w-full overflow-hidden border border-light-gray rounded-xl">
                <TableHeader className="font-semibold">
                  <TableRow>
                    <TableHead className="p-3 text-left">
                      <h6 className="text-sm font-semibold">Numéro</h6>
                    </TableHead>
                    <TableHead className="p-3 text-left">
                      <h6 className="text-sm font-semibold">Contenu</h6>
                    </TableHead>
                    <TableHead className="p-3 text-left">
                      <h6 className="text-sm font-semibold">
                        Titre
                      </h6>
                    </TableHead>
                    <TableHead className="p-3 text-left">
                      <h6 className="text-sm font-semibold">
                        Source
                      </h6>
                    </TableHead>
                    <TableHead className="p-3 text-left">
                      <h6 className="text-sm font-semibold">Actions</h6>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {adminAdd && adminAdd.map((currentContent, index) =>
                    currentContent.content_type === "qr_code" ? null : (
                      <TableRow
                        key={currentContent.id}
                        className="align-middle"
                      >
                        <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                          {index + 1}
                        </TableCell>
                        <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                          {currentContent?.content_type === "mov" ||
                            currentContent?.content_type === "mp4" ||
                            currentContent?.content_type === "gif" ? (
                            <VideoIcon
                              width={60}
                              height={60}
                              className="block object-cover rounded-xl w-[3.75rem] h-[3.75rem] flex-shrink-0 flex-grow-0"
                              stroke="#242331"
                            />
                          ) : (
                            <img
                              className="w-16 h-16 rounded-md"
                              src={
                                baseUrl +
                                "media/" +
                                currentContent.content_path
                              }
                              alt={currentContent.title}
                            />
                          )}
                        </TableCell>
                        <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                          {currentContent.title}
                        </TableCell>
                        <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                          {currentContent.source === "advertiser"
                            ? "Annonceur"
                            : "Vous"}
                        </TableCell>
                        <TableCell className="text-right py-2.5 flex items-center align-middle gap-2.5 h-14 pr-5">
                          <button
                            onClick={() => handleViewContent(currentContent)}
                            className="flex items-center justify-center w-6 h-6 rounded-md text-white bg-blue"
                          >
                            <ViewIcon className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleContentConfirmDelete(currentContent.content_id)}
                            className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                          >
                            <DeleteIcon stroke="white" width={14} height={14} />
                          </button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>

              <div
                className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showContentModal ? "" : "hidden"
                  }`}
              >
                <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
                  <div className="flex flex-col gap-5">
                    <div className="bg-background rounded-xl sm:p-8 p-5">
                      <label className="text-base font-medium"> {t("addContent")}</label>
                      <div className="flex flex-col gap-4 mt-5">
                        <div>
                          <label className="text-sm font-medium mb-2">{t("title")}</label>
                          <div>
                            <Input
                              type="text"
                              className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                              placeholder="Title"
                              value={packageTitle}
                              onChange={(e) => setPackageTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium mb-2">{t("packages")}</label>

                          <Select
                            placeholder="Select Package"
                            onValueChange={(val) => {
                              setPackageId(val.id);
                            }}
                          >
                            <SelectTrigger className="w-full cursor-pointer bg-white rounded-xl border-none h-[3.063rem]">
                              <SelectValue className="w-full" />
                            </SelectTrigger>
                            <SelectContent className="bg-white rounded-xl border-b" >
                              {allPackages?.map((val, ind) => {
                                return (
                                  <SelectItem
                                    key={ind}
                                    placeholder="Select Package"
                                    style={{ borderBottom: '1px solid #ccc' }}
                                    className="text-sm font-medium text-dark py-2 transition-all duration-500 hover:text-white hover:bg-blue"
                                    value={val}
                                  >
                                    <div >
                                      <h2 style={{ textTransform: 'uppercase' }}> <b> {val.title}</b></h2>
                                      <h3> Duration: <b>{val.content_duration} s</b></h3>
                                      <h3> Price: <b> {val.is_free ? 'Free' : '€' + val.price}</b></h3>
                                      <h3> {val.content_frequency ? 'Frequency' : 'Hours'} : <b>{val.content_frequency ? val.content_frequency : val.duration_in_hours} </b></h3>
                                    </div>
                                  </SelectItem>
                                );
                              })}
                            </SelectContent>
                          </Select>
                        </div>

                        <div className="mt-4">
                          <input
                            type="file"
                            accept="image/*,video/*"
                            onChange={handleFileChange}
                          />
                        </div>
                        {file && (
                          <div className="mt-4">
                            <Input
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              placeholder="Title"
                              disabled={true}
                            />
                          </div>
                        )}
                        {file && (
                          <div className="mt-4">
                            {fileType.startsWith("video/") ? (
                              <video
                                className="w-full h-64"
                                controls
                                src={URL.createObjectURL(file)}
                              />
                            ) : (
                              <img
                                className="w-full h-64"
                                src={URL.createObjectURL(file)}
                                alt={fileName}
                              />
                            )}
                          </div>
                        )}
                        {file && uploadProgress > 0 && (
                          <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                            <div
                              className="bg-blue-600 h-2.5 rounded-full"
                              style={{ width: `${uploadProgress}%` }}
                            />
                          </div>
                        )}
                        <div className="text-right">
                          <Button
                            size="sm"
                            className="w-50 px-12 mr-5 bg-gray-300 text-gray-700"
                            // className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
                            onClick={() => setShowContentModal(false)}
                          >
                            Close
                          </Button>
                          <Button
                            size="sm"
                            className="w-50 px-12"
                            onClick={handleUpload}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${viewShowContentModal ? "" : "hidden"
                  }`}
              >
                <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
                  <div className="flex flex-col gap-5">
                    <div className="bg-background rounded-xl sm:p-8 p-5">
                      <label className="text-base font-medium">{selectedContent?.title}</label>
                      <div className="flex flex-col gap-4 mt-5">

                        {selectedContent?.content_type === "mov" ||
                          selectedContent?.content_type === "mp4" ||
                          selectedContent?.content_type === "gif" ? (
                          <video
                            className="w-full h-full"
                            controls
                            src={baseUrl + "media/" + selectedContent?.content_path}
                          />
                        ) : (
                          <img
                            className="w-full h-auto"
                            src={baseUrl + "media/" + selectedContent?.content_path}
                            alt={selectedContent?.title}
                          />
                        )}
                        <div className="text-right">
                          <Button
                            size="sm"
                            className="w-50 px-12 bg-gray-300 text-gray-700"
                            onClick={() => setViewShowContentModal(false)}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {deleteModal && (
              <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                <div className="w-full max-w-lg p-6 bg-white rounded-lg">
                  <h3 className="mb-4 text-lg font-semibold">Confirm Deletion</h3>
                  <p className="mb-4">Are you sure you want to delete this Package?</p>
                  <div className="flex justify-end gap-4">
                    <button
                      onClick={handleModalClose}
                      className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleModalConfirm}
                      className="px-4 py-2 text-white bg-red-600 rounded bg-red"
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              </div>
            )}

            {deleteContentModal && (
              <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                <div className="w-full max-w-lg p-6 bg-white rounded-lg">
                  <h3 className="mb-4 text-lg font-semibold">Confirm Deletion</h3>
                  <p className="mb-4">Are you sure you want to delete this Content?</p>
                  <div className="flex justify-end gap-4">
                    <button
                      onClick={handleContentModalClose}
                      className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleContentModalConfirm}
                      className="px-4 py-2 text-white bg-red-600 rounded bg-red"
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </section>
        </>


      )
      }
    </AppLayout >
  );
};

export default EditUser;
