import React, { Fragment, useState } from "react";

// Components
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import Login from "../../Login";
import Signup from "../../Signup";
import { useTranslation } from "react-i18next";

const AuthTabs = () => {
  const { t } = useTranslation("translation");
  const [isOpen, setIsOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  return (
    <Fragment>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger
          className="bg-gradient-primary rounded-xl sm:text-sm text-xs font-medium sm:py-3.5 py-2.5 sm:px-5 px-3"
          onClick={handleDialogOpen}
        >
          {t('myAccount')}
        </DialogTrigger>
        <DialogContent notDisable={true} className="bg-white ms:px-7 px-5 py-5 rounded-xl sm:max-w-lg max-w-sm">
          <DialogDescription>
            <Tabs defaultValue="signup" className="mt-14">
              <TabsList className="bg-background p-1 rounded-xl h-[3.375rem] w-full">
                <TabsTrigger
                  className="text-sm font-medium capitalize text-dark bg-background rounded-xl py-3.5 w-full data-[state=active]:bg-blue data-[state=active]:text-white"
                  value="signup"
                >
                  {t('register')}
                </TabsTrigger>
                <TabsTrigger
                  className="text-sm font-medium capitalize text-dark bg-background rounded-xl py-3.5 w-full data-[state=active]:bg-blue data-[state=active]:text-white"
                  value="signin"
                >
                  {t('logIn')}
                </TabsTrigger>
              </TabsList>
              <TabsContent value="signup">
                <Signup
                  handleDialogClose={handleDialogClose}
                />
              </TabsContent>
              <TabsContent value="signin">
                <Login />
              </TabsContent>
            </Tabs>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AuthTabs;
