import React, { Fragment } from "react";

// Assets
import partnerImg1 from "../../../../assets/images/partner-img1.png";
import partnerImg2 from "../../../../assets/images/partner-img2.png";
import partnerImg3 from "../../../../assets/images/partner-img3.png";
import partnerImg4 from "../../../../assets/images/partner-img4.png";
import partnerImg5 from "../../../../assets/images/partner-img5.png";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation("translation");
  return (
    <Fragment>
      <section className="md:py-20 py-10 text-center">
        <div className="md:container mx-auto px-5">
          <h2 className="sm:text-4xl text-3xl font-playfair font-bold uppercase ">
            {t("section5Head")}
          </h2>
          <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:mt-14 mt-10">
            <div>
              <img
                src={partnerImg1}
                width={150}
                height={67}
                className="block object-contain sm:w-[9.375rem] w-1/2 h-[6.25rem] mx-auto"
                alt=""
              />
            </div>
            <div>
              <img
                src={partnerImg2}
                width={200}
                height={24}
                className="block object-contain sm:w-[12.5rem] w-1/2 h-[6.25rem] mx-auto"
                alt=""
              />
            </div>
            <div>
              <img
                src={partnerImg3}
                width={200}
                height={42}
                className="block object-contain sm:w-[12.5rem] w-1/2 h-[6.25rem] mx-auto"
                alt=""
              />
            </div>
            <div>
              <img
                src={partnerImg4}
                width={200}
                height={64}
                className="block object-contain sm:w-[12.5rem] w-1/2 h-[6.25rem] mx-auto"
                alt=""
              />
            </div>
            <div>
              <img
                src={partnerImg5}
                width={200}
                height={40}
                className="block object-contain sm:w-[12.5rem] w-1/2 h-[6.25rem] mx-auto"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Partners;
