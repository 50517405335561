import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Assets
import logo from "../../assets/images/logo.png";
import AuthDialog from "../../pages/auth/components/authdialog/index";
import { useTranslation } from 'react-i18next';
import { Global } from "../../assets/icons/Icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { DropdownMenuArrow } from "@radix-ui/react-dropdown-menu";


const Header = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Change the language
  };
  return (
    <Fragment>
      <header className="bg-white border-b border-background py-3.5 sticky top-0 z-[2] w-full">
        <div className="md:container mx-auto px-5">
          <div className="flex items-center justify-between">
            <Link to="/">
              <img
                src={logo}
                width={200}
                height={23}
                className="block object-cover sm:w-[12.5rem] w-[6.5rem]"
                alt=""
              />
            </Link>
            <div className="flex items-center sm:gap-12 gap-4">
              <DropdownMenu>
                <DropdownMenuTrigger className="flex xl:justify-end justify-between items-center gap-2.5">
                  <Global className="text-blue" stroke="blue" width={30} height={30} />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-white rounded-xl ms-3">
                  <DropdownMenuArrow className="fill-current text-white" />
                  <DropdownMenuItem className="text-sm font-medium text-dark rounded-xl transition-all duration-500 hover:text-white hover:bg-blue cursor-pointer">
                    <p onClick={() => changeLanguage('en')}>English</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem className="text-sm font-medium text-dark rounded-xl transition-all duration-500 hover:text-white hover:bg-blue cursor-pointer">
                    <p onClick={() => changeLanguage('fr')}>French</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem className="text-sm font-medium text-dark rounded-xl transition-all duration-500 hover:text-white hover:bg-blue cursor-pointer">
                    <p onClick={() => changeLanguage('es')}>Spanish</p>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <Link
                to="/contact-us"
                className="sm:text-base text-xs font-medium p-1 transition-all duration-500 hover:text-blue"
              >
              {t('contactUs')}
              </Link>
              {/* <Link
                to="/"
                className="bg-gradient-primary rounded-xl text-sm font-medium sm:py-3.5 py-2.5 sm:px-5 px-3"
              >
                My Account
              </Link> */}
              <AuthDialog />
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
