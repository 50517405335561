import React, { Fragment } from "react";

// Components
import {
  ConnectIcon,
  EditIcon,
  LockIcon,
  MonitorIcon,
  SemiCircleYellow,
} from "../../../../assets/icons/Icons";
import { useTranslation } from "react-i18next";

const Feature = () => {
  const { t } = useTranslation("translation");

  return (
    <Fragment>
      <section className="md:py-20 py-10 relative">
        <div className="md:container mx-auto px-5">
          <SemiCircleYellow
            fill="#D5FC71"
            width={80}
            height={220}
            className="absolute opacity-60 bottom-0 right-0 -z-10"
          />
          <h2 className="sm:text-4xl text-3xl font-playfair font-bold uppercase text-center ">
           {t("subHead3")}
          </h2>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mt-12">
            <div className="flex flex-col gap-5 bg-background rounded-xl sm:p-8 p-6 h-full">
              <MonitorIcon
                width={50}
                height={50}
                className="sm:w-[3.125rem] sm:h-[3.125rem] w-10 h-10"
                fill="#242331"
                fillLight="#503AC9"
              />
              <h3 className="text-2xl font-playfair font-bold capitalize">
              {t("subCard1Head")} 
              </h3>
              <p className="text-base ">
              {t("subCard1Detail")}   
              </p>
            </div>
            <div className="flex flex-col gap-5 bg-background rounded-xl sm:p-8 p-6 h-full">
              <EditIcon
                width={50}
                height={50}
                className="sm:w-[3.125rem] sm:h-[3.125rem] w-10 h-10"
                fill="#242331"
                fillLight="#503AC9"
              />
              <h3 className="text-2xl font-playfair font-bold capitalize">
              {t("subCard2Head")}   
              </h3>
              <p className="text-base ">
              {t("subCard2Detail")}   
              </p>
            </div>
            <div className="flex flex-col gap-5 bg-background rounded-xl sm:p-8 p-6 h-full">
              <ConnectIcon
                width={50}
                height={50}
                className="sm:w-[3.125rem] sm:h-[3.125rem] w-10 h-10"
                fill="#242331"
                fillLight="#503AC9"
              />
              <h3 className="text-2xl font-playfair font-bold capitalize">
              {t("subCard3Head")}  
              </h3>
              <p className="text-base ">
              {t("subCard3Detail")}  
              </p>
            </div>
            <div className="flex flex-col gap-5 bg-background rounded-xl sm:p-8 p-6 h-full">
              <LockIcon
                width={50}
                height={50}
                className="sm:w-[3.125rem] sm:h-[3.125rem] w-10 h-10"
                fill="#242331"
                fillLight="#503AC9"
              />
              <h3 className="text-2xl font-playfair font-bold capitalize">
              {t("subCard4Head")}  
              </h3>
              <p className="text-base ">
              {t("subCard4Detail")}   
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Feature;
