import React, { useState } from "react";
import AppLayout from "../../../components/applayout/AppLayout";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import interceptor from "../../../hooks/intercepter";
import useToken from "../../../hooks/useToken";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../components/ui/Loader";
import ReactSwitch from "react-switch";

const EditPackages = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState("");
  const { token } = useToken();
  const [user, setUser] = useState(location.state?.user || {});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };
  const [currentItems, setcurrentItems] = useState([]);

  const [duration, setDuration] = useState(user.duration_in_hours);
  const [frequency, setFrequency] = useState(user.content_frequency); // Default frequency
  const [contentMode, setContentMode] = useState(user.content_frequency ? 'frequency' : 'hours'); // Default frequency
  const [type, setType] = useState(user.is_free);
  const handleEditUser = async () => {
    if (parseInt(user.content_frequency) < 1) {
      toast.error("Frequency must be greater than 0");
      return;
    }
    if (parseInt(user.content_duration) < 1) {
      toast.error("Duration must be greater than 0");
      return;
    }
    debugger
    const data = {
      title: user.title,
      price: parseFloat(user.price),
      content_frequency: contentMode == 'frequency' ? parseInt(frequency) : '',
      duration_in_hours: contentMode == 'hours' ? parseInt(duration) : '',
      is_free: type,
    };
    if (contentMode == 'frequency') {
      delete data.duration_in_hours;
    }
    if (contentMode == 'hours') {
      delete data.content_frequency;
    }

    try {
      setLoading(true);
      const response = await interceptor.axiosPut(
        "PATCH",
        `admin/package/${user.id}`,
        data,
        token
      );
      if (response.status === 200) {
        console.log("Package updated successfully");
        setLoading(false);

        navigate("/admin/packages");
        toast.success("Package Updated Successfully");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
      console.error("Failed to update package:", error);
    }
  };

  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <section className="sm:mb-24 mb-20">
          <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
            Modifier le package
          </h5>
          <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
            <div className="flex flex-col gap-5">
              <div className="bg-background rounded-xl sm:p-8 p-5">
                <label className="text-base font-medium">Emballer</label>
                <div className="flex flex-col gap-4 mt-5">
                  <div>
                    <Input
                      type="text"
                      name="title"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Title"
                      value={user.title}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <Input
                      type="text"
                      name="price"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Price"
                      value={user.price}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <select
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      value={contentMode}
                      onChange={(e) => setContentMode(e.target.value)}
                    >
                      <option value="frequency">Content in Frequency</option>
                      <option value="hours">Content in Hours</option>
                    </select>
                  </div>
                  {contentMode == 'frequency' && <div>
                    <Input
                      type="number"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Content Frequency e.g 1,2,3.."
                      value={frequency}
                      min={1}
                      onChange={(e) => { setFrequency(e.target.value); setDuration(''); }}
                    />
                  </div>
                  }
                  {contentMode == 'hours' && <div>
                    <Input
                      type="number"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Content Repeat for hour e.g 1,2,3.."
                      value={duration}
                      min={1}
                      onChange={(e) => { setDuration(e.target.value); setFrequency('') }}
                    />
                  </div>
                  }
                  <div className="w-fit valition">
                    <span className="px-3 pt-1 font-medium text-sm" style={{ verticalAlign: 'middle' }}>Free</span>
                    <ReactSwitch
                      checked={type}
                      className="react-switch"
                      onChange={(e) => {
                        setType(e);
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      size="sm"
                      className="w-full px-12"
                      onClick={handleEditUser}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </AppLayout>
  );
};

export default EditPackages;
