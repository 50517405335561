import React, { Fragment, useState } from "react";
import { Textarea } from "../../../../components/ui/textarea";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import {
  SemiCircleLightBlue,
  SemiCircleYellow,
} from "../../../../assets/icons/Icons";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../../components/ui/form";
import interceptor from "./../../../../hooks/intercepter";
import { PhoneNumberInput } from "../../../../components/ui/phoneInput";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const contactFormSchema = z.object({
  name: z.string().min(1, "Name is required!"),
  email: z.string().min(1, "Email is required!").email("Invalid email address"),
  phone: z
    .string()
    .min(1, "Phone is required!")
    .regex(/^\+33\d{9}$|^\+[1-9]\d{10,12}$/, "Invalid phone number"),
  company: z.string().min(1, "Company is required!"),
  description: z.string(),
});

const ContactForm = () => {
  const { t } = useTranslation("translation");
  const form = useForm({
    resolver: zodResolver(contactFormSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      company: "",
      description: "",
    },
  });

  const [error, setError] = useState(null);

  const submitContactForm = (values) => {
    setError(null);

    interceptor
      .axiosPost("users/contact-us", values, null)
      .then((res) => {
        if (res.status === 200) {
          form.reset();
          toast.success("Submitted Successfully");
        } else {
          console.log("Error submitting form: ", res);
        }
      })
      .catch((err) => {
        setError(
          "An error occurred while submitting the form. Please try again."
        );
        return err;
      });
  };

  return (
    <Fragment>
      <section className="bg-background">
        <div className="px-5 mx-auto md:container">
          <div className="flex flex-col items-center justify-center gap-2 sm:gap-4 md:h-56 h-44">
            <h2 className="text-3xl font-bold md:text-5xl">{t("contactUsHead")}</h2>
            {/* <p className="text-sm text-center">
              {t("contactUsSubHead")}
            </p> */}
          </div>
        </div>
      </section>
      <section className="py-10 overflow-hidden md:py-14">
        <div className="px-5 mx-auto md:container">
          <div className="flex flex-col items-center justify-between lg:flex-row gap-7">
            <div className="w-full pl-0 lg:col-w-4/12 lg:pl-20">
              <div className="flex flex-col sm:items-start items-center gap-2.5">
                {/* <span className="text-xl font-medium text-blue">
                  {t("contactUsHead")}
                </span> */}
                <h4 className="md:text-3.3xl text-2xl font-bold capitalize">
                  {t("contactUsSubName")}
                </h4>
              </div>
            </div>
            <div className="w-full lg:col-w-6/12">
              <div className="relative flex flex-col gap-5 px-5 bg-background rounded-xl md:py-12 py-9 md:px-14">
                <SemiCircleLightBlue
                  fill="#23E3EE"
                  width={49}
                  height={100}
                  className="absolute opacity-100 md:top-10 -top-8 -left-8 z-[1]"
                />
                <SemiCircleYellow
                  fill="#D5FC71"
                  width={80}
                  height={220}
                  className="absolute opacity-100 -bottom-24 sm:right-20 right-7 transform rotate-90 md:w-20 w-16 z-[1]"
                />
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(submitContactForm)}
                    className="flex flex-col gap-6"
                  >
                    <div className="flex flex-col gap-5">
                      <div>
                        <FormField
                          control={form.control}
                          name="name"
                          render={({ field }) => (
                            <FormItem className="space-y-0">
                              <FormControl>
                                <Input
                                  className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                                  placeholder={t("contactName")}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div>
                        <FormField
                          control={form.control}
                          name="email"
                          render={({ field }) => (
                            <FormItem className="space-y-0">
                              <FormControl>
                                <Input
                                  className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                                  placeholder={t("contactemail")}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div>
                        <FormField
                          control={form.control}
                          name="phone"
                          render={({ field }) => (
                            <FormItem className="space-y-0 contact-form">
                              <FormControl>
                                <PhoneNumberInput
                                  className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                                  placeholder={t("contactphone")}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div>
                        <FormField
                          control={form.control}
                          name="company"
                          render={({ field }) => (
                            <FormItem className="space-y-0">
                              <FormControl>
                                <Input
                                  className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                                  placeholder={t("contactCompany")}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div>
                        <FormField
                          control={form.control}
                          name="description"
                          render={({ field }) => (
                            <FormItem className="space-y-0">
                              <FormControl>
                                <Textarea
                                  className="w-full px-5 text-sm font-medium bg-white border-none text-dark h-36 rounded-xl"
                                  placeholder={t("contactDescription")}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                            </FormItem>
                          )}
                        />
                      </div>
                      {error && (
                        <div className="text-sm font-semibold text-red">
                          {error}
                        </div>
                      )}
                      <div>
                        <Button
                          type="submit"
                          size="sm"
                          className="md:px-12 px-9"
                        >
                          {t("contactUsButton")}
                        </Button>
                      </div>
                    </div>
                  </form>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ContactForm;
