import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";

// Components
import Home from "./pages/home";
import ContactUs from "./pages/contact-us";
import Dashboard from "./pages/broadcaster/dashboard";
import AddContent from "./pages/broadcaster/add-content";
import Settings from "./pages/broadcaster/settings";
import AdvertiserAddContent from "./pages/advertiser/add-content";
import Thankyou from "./pages/advertiser/thankyou";
import Users from "./pages/admin/users";
import AddUser from "./pages/admin/add-user";
import EditUser from "./pages/admin/edit-user";
import Packages from "./pages/admin/packages";
import PrivateRoutes from "./routes/PrivateRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import BroadCasterRoutes from "./routes/BroadCasterRoutes";
import AddPackages from "./pages/admin/add-package";
import EditPackages from "./pages/admin/edit-package";
import ResetPassword from "./pages/broadcaster/reset-password";
import { Toaster } from "react-hot-toast";
import ViewUser from "./pages/admin/view-user";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./App.css";
import Terms from "./pages/terms";
import Keywords from "./pages/admin/keywords";
import AddScreens from "./pages/broadcaster/add-screen";

// const stripePromise = loadStripe(
//   "pk_test_51Ok1PQCSI4ixFrp29AWGY7gK5e2DESO8xfrU7LG3PYBd5TcBiiOE7WSTXuXXF7sISF0cEbPpOJBrb6WVvSRRPoNw0038B8WGQv"
// );

function App() {
  return (
    <div>
      <BrowserRouter>
        {/* <Elements stripe={stripePromise}> */}
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/termsOfUse" index element={<Terms />} />

          <Route
            path="advertiser/add-content/:id/:broadcasterId"
            element={<AdvertiserAddContent />}
          />
          <Route
            path="advertiser/thankyou/:id/:broadcasterId"
            element={<Thankyou />}
          />
          <Route path="contact-us" element={<ContactUs />} />

          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<AdminRoutes />}>
              <Route path="/" element={<Navigate replace to="/admin/users" />} ></Route>
              <Route path="admin/users" element={<Users />} />
              <Route path="admin/add-user" element={<AddUser />} />
              <Route path="admin/edit-user" element={<EditUser />} />
              <Route path="admin/view-user" element={<ViewUser />} />
              <Route path="admin/packages" element={<Packages />} />
              <Route path="admin/keywords" element={<Keywords />} />
              <Route path="admin/add-packages" element={<AddPackages />} />
              <Route path="admin/edit-packages" element={<EditPackages />} />
            </Route>
            <Route path="/" element={<BroadCasterRoutes />}>
              <Route
                path="/"
                element={<Navigate replace to="/broadcaster/dashboard" />}
              ></Route>
              <Route path="broadcaster/dashboard" element={<Dashboard />} />
              <Route
                path="broadcaster/reset-password"
                element={<ResetPassword />}
              />

              <Route path="broadcaster/add-content" element={<AddContent />} />
              <Route path="broadcaster/add-screen" element={<AddScreens />} />
              <Route path="broadcaster/settings" element={<Settings />} />
            </Route>
          </Route>
        </Routes>
        {/* </Elements> */}
      </BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default App;
