import React, { useState, useEffect } from "react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import AppLayout from "../../../components/applayout/AppLayout";
import useToken from "../../../hooks/useToken"; // Import the useToken hook
import interceptor from "../../../hooks/intercepter.js"; // Interceptor
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { user, token } = useToken();
  const { t } = useTranslation("translation");
  const FormSchema = z
    .object({
      email: z.string().min(1, "Email is required").email("Invalid email"),
      new_password: z
        .string()
        .min(1, "Password is required")
        .min(8, "Password must have at least 8 characters"),
      confirm_password: z.string().min(1, "Password confirmation is required"),
    })
    .refine((data) => data.new_password === data.confirm_password, {
      path: ["confirm_password"],
      message: "Passwords do not match",
    });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: user?.email || "",
      new_password: "",
      confirm_password: "",
    },
  });

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
    }
  }, [user, setValue]);

  const onSubmit = async (formData) => {
    try {
      const response = await interceptor.axiosPost(
        "users/auth/reset-password",
        formData,
        null
      );
      if (response.status === 200) {
        toast.success("Password reset successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      toast.error("Failed to update Password Reset. Please try again.");
    }
  };

  return (
    <AppLayout>
      <section className="sm:mb-24 mb-20">
        <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
          {t("changePassword")}
        </h5>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-7 sm:my-8 my-6"
        >
          <div className="grid md:grid-cols-3 grid-cols-1 gap-7">
            <div>
              <Input
                type="text"
                {...register("email")}
                className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                placeholder="Email"
                disabled={true}
              />
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}
            </div>
            <div>
              <Input
                type="text"
                {...register("new_password")}
                className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                placeholder={t("newPassword")} 
              />
              {errors.new_password && (
                <span className="text-red">{errors.new_password.message}</span>
              )}
            </div>
            <div>
              <Input
                type="text"
                {...register("confirm_password")}
                className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                placeholder={t("confirmPassword")} 
              />
              {errors.confirm_password && (
                <span className="text-red">
                  {errors.confirm_password.message}
                </span>
              )}
            </div>
            <div className="flex justify-start">
              <Button size="sm" type="submit" className="w-fit px-12 mr-3">
                {t("change")}
              </Button>
            </div>
          </div>
        </form>
      </section>
    </AppLayout>
  );
};

export default ResetPassword;
