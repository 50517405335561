import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  DeleteIcon,
  Edit,
  NoUserIcon,
  SearchIcon,
} from "../../../assets/icons/Icons";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import AppLayout from "../../../components/applayout/AppLayout";
import useToken from "../../../hooks/useToken"; // Import the useToken hook
import interceptor from "../../../hooks/intercepter.js";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import { set } from "lodash";
import StatusToggle from "../users/StatusToggle";
import { useTranslation } from "react-i18next";

const Keywords = () => {
  const { t } = useTranslation("translation");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDurModal, setShowDurModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const { token, removeToken } = useToken(); // Use the useToken hook

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = users.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(users.length / itemsPerPage);
  const [words, setWord] = useState();
  const [type, setType] = useState(1);
  const [id, setId] = useState();
  /*   const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    setItemOffset(newOffset);
  }; */
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  console.log("currentItems", currentItems);
  useEffect(() => {
    fetchList();
  }, []);
  const fetchList = async () => {
    try {
      const response = await interceptor.axiosGet(
        "get",
        "admin/words",
        token
      );
      if (response.status === 200) {
        setUsers(response?.data?.data?.words);
      }
    } catch (error) {
      console.error("Failed to fetch keyword:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      // Send delete request
      const response = await interceptor.axiosDelete(
        "DELETE",
        `admin/words/${id}`,
        null,
        token
      );

      // Check response status
      if (response.status === 200) {
        setLoading(false);
        fetchList();
        toast.success("Package Deleted Successfully");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };
  const handleEdit = (keyword) => {
    setWord(keyword.word);
    setType(keyword.is_black_listed);
    setId(keyword.id);
    setShowDurModal(true)
  };

  const handleConfirmDelete = (id) => {
    setId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowDurModal(false);
    setShowModal(false);
    setWord('')
    setType('')
    setId(null);

  };

  const handleModalConfirm = () => {
    handleDelete();
    handleModalClose();
  };



  const handleModalDurClose = () => {
    debugger
    if (type && words) {

      setLoading(true)
      let modal = { is_black_listed: type==1 ? true : false, word: words };
      let url = id ? `admin/words/${id}` : 'admin/words';
      try {
        interceptor.axiosPut(id ? 'Put' : "Post", url, modal, token
        ).then((response) => {
          setLoading(false)
          if (response.status === 200) {
            fetchList();
            setShowDurModal(false);
            setWord('')
            setType('')
            setId(null);
            toast.success("Status Updated Successfully");
          }
        })
      } catch (error) {
        toast.error(error?.message);
        console.error("Update request failed:", error);
      }
    } else {
      alert('Please fill all fields')
    }


  };



  // const handleChangeStatus = async (user) => {
  //   try {
  //     const response = await interceptor.axiosPut("PUT", `content-validation-settings`,
  //       { content_validation_enabled: !status }, token
  //     );
  //     if (response.status === 200) {
  //       setStatus(response?.data.content_validation_enabled)
  //       toast.success("Status Updated Successfully");
  //     }
  //   } catch (error) {
  //     toast.error(error?.message);
  //     console.error("Update request failed:", error);
  //   }
  // };

  return (
    <AppLayout>
      <section className="mb-20 sm:mb-24">
        <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
          {t("keywordHeading")}
        </h5>

        <div className="flex flex-wrap items-center justify-start gap-3  sm:my-4 float-right">
          <Button
            size="sm"
            onClick={() => setShowDurModal(true)}
            className="px-8 w-fit right"
          >
            {t("addKeyword")}
          </Button>
        </div>
        <div className="clear-both mt-8">
          <div className="mt-5 overflow-x-auto whitespace-nowrap b-1">
            {loading ? (
              <p>Loading...</p>
            ) : users.length === 0 ? (
              <div className="flex flex-col items-center gap-5 mt-8 md:mt-12">
                <NoUserIcon
                  width={150}
                  height={96}
                  className="w-24 h-16 sm:w-36 sm:h-24"
                />
                <p className="text-lg font-medium text-center">
                  {" "}
                  {t("noRecordFound")}
                </p>
                <Button
                  size="sm"
                  className="w-fit px-9"
                  onClick={() => setShowDurModal(true)}
                >
                  {t("addKeyword")}
                </Button>
              </div>
            ) : (
              <div className="w-full overflow-auto">
                <Table className="w-full  border border-2 border-light-gray rounded-2xl" style={{ borderRadius: '5px' }}>
                  <TableHeader>
                    <TableRow className="border-none bg-background">
                      <TableHead className="text-sm font-medium">  {t("keyword")}</TableHead>
                      <TableHead className="pr-8">{t("type")}</TableHead>
                      <TableHead className="pr-8 text-right">{t("action")}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentItems.map((keyword) => (
                      <TableRow key={keyword.id} className="even:bg-background">
                        <TableCell className="text-sm font-normal py-2.5 h-auto">
                          {keyword.word}
                        </TableCell>
                        <TableCell className="text-sm font-normal py-2.5 h-auto">
                          {keyword.is_black_listed ? 'Backlist' : 'Whitelist'}
                        </TableCell>
                        <TableCell className="text-right py-2.5 flex items-center justify-end gap-2.5 h-14 pr-5">
                          <button
                            onClick={() => handleEdit(keyword)}
                            className="flex items-center justify-center w-6 h-6 rounded-md bg-blue"
                          >
                            <Edit stroke="white" width={14} height={14} />
                          </button>
                          <button
                            onClick={() => handleConfirmDelete(keyword.id)}
                            className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                          >
                            <DeleteIcon stroke="white" width={14} height={14} />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
          <div className="flex mt-6" style={{ justifyContent: 'right' }}>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </div>
        </div>
      </section>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-6 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">Confirm Deletion</h3>
            <p className="mb-4">
              Are you sure you want to remove this Keywords?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleModalClose}
                className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleModalConfirm}
                className="px-4 py-2 text-white bg-red-600 rounded bg-red"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showDurModal ? "" : "hidden"
          }`}
      >

        <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
          <div className="flex flex-col gap-5">
            <div className="bg-background rounded-xl sm:p-8 p-5">
              <label className="text-base font-medium">
                {t("addKeyword")}
              </label>
              <div className="flex flex-col gap-4 mt-5">
                <div>
                  <Input
                    type="text"
                    className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl"
                    placeholder="Blacklist/Whitelist"
                    name="type"
                    value={words}
                    onChange={(e) => setWord(e.target.value)}
                  />
                </div>
                <div>
                  <select
                    className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                  >
                    <option value=''>Select one option</option>
                    <option value={1}>{t("Blacklist")}</option>
                    <option value={2}>{t("Whitelist")}</option>
                  </select>
                </div>
                <div className="text-right">
                  <Button
                    size="sm"
                    className="w-50 px-12 mr-5 bg-gray-300 text-gray-700"
                    // className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
                    onClick={() => handleModalClose()}
                  >
                    Close
                  </Button>
                  <Button
                    size="sm"
                    className="w-50 px-12"
                    onClick={handleModalDurClose}
                  >
                    Save

                    {loading && <svg aria-hidden="true" class="inline ml-4 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="gray" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="red" />
                    </svg>
                    }
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </AppLayout>
  );
};

export default Keywords;
