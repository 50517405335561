import React, { useState, useEffect, Fragment } from "react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import AppLayout from "../../../components/applayout/AppLayout";
import useToken from "../../../hooks/useToken"; // Import the useToken hook
import interceptor from "../../../hooks/intercepter.js"; // Interceptor
import { PhoneNumberInput } from "../../../components/ui/phoneInput";
import { baseUrl } from "../../../hooks/intercepter.js";
import { z } from "zod"; // Import Zod
import { useForm } from "react-hook-form"; // Import React Hook Form
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "../../../components/ui/form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Edit, EditIcon, ViewIcon }
  from "../../../assets/icons/Icons";
import StatusToggle from "../../admin/users/StatusToggle";
import { useTranslation } from "react-i18next";
import { RotateCcw } from "lucide-react";

// Define validation schema using Zod
const schema = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().min(1, "Email is required").email("Invalid email"),
  phone: z
    .string()
    .min(1, "Phone is required!")
    .regex(/^\+33\d{9}$|^\+[1-9]\d{10,12}$/, "Invalid phone number"),
  location_name: z.string().min(1, { message: "Location name is required" }),
  location_address: z
    .string()
    .min(1, { message: "Location address is required" }),
});

const Settings = () => {
  const { t } = useTranslation("translation");
  const { user, token } = useToken();
  const [qrCodeUrl, setQrCodeUrl] = useState(null); // State to store QR code URL
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [openQrModal, setOpenQrModal] = useState();
  const [checkFile, setCheckFile] = useState('');

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      location_name: "",
      location_address: "",
    },
  });

  useEffect(() => {

    if (user) {
      setValue("first_name", user.first_name || "");
      setValue("last_name", user.last_name || "");
      setValue("email", user.email || "");
      setValue("phone", user.phone || "");
      setValue("location_name", user.location_name || "");
      setValue("location_address", user.location_address || "");
      getStatus();
    }
  }, [user, setValue]);

  useEffect(() => {
    if (user) {
      fetchQrCode(); // Fetch QR code when component mounts or user updates
    }
  }, [user]);

  const fetchQrCode = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/broadcaster/${user.id}/qrcode`,
        token
      );

      if (response.data && response.data.data && response.data.data.filename) {
        const filename = response.data.data.filename;
        setQrCodeUrl(baseUrl + `media/${filename}`);
      } else {
        throw new Error("Filename is missing in the response data.");
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
      toast.error("Failed to fetch QR code. Please try again.");
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const onSubmit = async (data) => {
    try {
      const response = await interceptor.axiosPut(
        "PATCH",
        "users/me",
        data,
        token
      );
      console.log("User updated:", response.data);
      const updatedUser = {
        ...user,
        ...data,
      };
      localStorage.setItem("@tokenData", JSON.stringify(updatedUser));
      toast.success("User information updated successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user information. Please try again.");
    }
  };

  const onfileChange = (e) => {
    document.getElementById('file').click();
  }
  const [formData, setformData] = useState(new FormData());
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setLoading(true);
    const selectedFile = e.target.files[0];
    console.log(selectedFile);
    debugger
    if (selectedFile) {
      const newFormData = new FormData();
      newFormData.append("background_image", selectedFile);
      setformData(newFormData);
      const response = interceptor.axiosFormData("Put", `users/broadcaster/${user.id}/qrcode`, newFormData, token,
      ).then((response) => {
        setLoading(false);
        debugger
        const blob = response.data;
        const qrCodeUrl = URL.createObjectURL(blob);
        setOpenQrModal(true);
        setCheckFile(qrCodeUrl);
        console.log("QR code uploaded:", response.data);
        // fetchQrCode();
      })
    }
  };

  const CloseModal = () => {

    setformData(new FormData());
    setOpenQrModal(false);
    setCheckFile('');
  }

  const SubmitFileChange = () => {
    console.log(formData);
    if (formData) {
      const response = interceptor.axiosPostFormData(`users/broadcaster/${user.id}/qrcode`, formData, token,
      ).then((response) => {
        console.log("QR code uploaded:", response.data);
        fetchQrCode();
        setformData(new FormData());
        setOpenQrModal(false);
        setCheckFile('');
        toast.success("QR code uploaded successfully!");
      })
    }
  };

  const handleChangeStatus = async () => {
    try {
      const response = await interceptor.axiosPut("PUT", `content-validation-settings`,
        {
          content_validation_enabled: !status,
          user_id: user?.id
        }, token
      );
      if (response.status === 200) {
        setStatus(response?.data.data.content_validation_enabled)
        toast.success("Status Updated Successfully");
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Update request failed:", error);
    }
  };

  const getStatus = async () => {
    await interceptor.axiosGet("GET", `content-validation-settings?user_id=${user.id}`, token
    ).then((res) => {
      debugger
      if (res.status === 200) {
        setStatus(res?.data.data.content_validation_enabled)
      }
    })
  };
  const resetQrCode = () => {
    setLoading(true);
    interceptor.axiosFormData("Patch", `users/broadcaster/${user.id}/qrcode`, null, token,
    ).then((response) => {
      setLoading(false)
      fetchQrCode();
      setformData(new FormData());
      setOpenQrModal(false);
      setCheckFile('');
      toast.success("QR code Reset successfully!");
    })
  }

  return (
    <AppLayout>
      <section className="sm:mb-24 mb-20">
        <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">

          {t("settingHeading")}
        </h5>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-7 sm:my-8 my-6"
        >
          <div className="grid md:grid-cols-3 grid-cols-1 gap-7">
            <div>
              <FormField
                control={control}
                name="first_name"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="first_name"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder={t("firstName")}
                    />
                    {errors.first_name && (
                      <p className="text-sm text-red">
                        {errors.first_name.message}
                      </p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="last_name"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="last_name"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder={t("lastName")}
                    />
                    {errors.last_name && (
                      <p className="text-sm text-red">
                        {errors.last_name.message}
                      </p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="email"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder={t("email")}
                      disabled={true}
                    />
                    {errors.email && (
                      <p className="text-sm text-red">{errors.email.message}</p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <Fragment>
                    <PhoneNumberInput
                      type="text"
                      name="phone"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder={t("phone")}
                    />
                    {errors.phone && (
                      <p className="text-sm text-red">{errors.phone.message}</p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="location_name"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="location_name"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder={t("locationName")}
                    />
                    {errors.location_name && (
                      <p className="text-sm text-red">
                        {errors.location_name.message}
                      </p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="location_address"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="location_address"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder={t("locationAddress")}
                    />
                    {errors.location_address && (
                      <p className="text-sm text-red">
                        {errors.location_address.message}
                      </p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div className="px-8 w-fit valition">
              <span className="px-3 pt-1 font-medium text-sm" style={{ verticalAlign: 'middle' }}>    {t("validateContent")}   </span>
              <StatusToggle
                isActive={status}
                onToggle={() => handleChangeStatus()}
              />
            </div>

          </div>
          <div className="flex items-center justify-center">
            <span
              style={{
                cursor: 'pointer'
              }}
              className="mr-4"
            >
              <button
                type="button"
                onClick={resetQrCode}
                className="flex items-center justify-center w-6 h-6 text-white rounded-md bg-blue mb-3"
              >
                <RotateCcw stroke="white" width={14} height={14} />
              </button>
              <button onClick={onfileChange}
                type="button"
                className="flex items-center justify-center w-6 h-6 rounded-md bg-lime-700"
              >
                <Edit stroke="white" width={14} height={14} />
              </button>
            </span>

            <div className="mt-4 file-upload" style={{ textAlign: '-webkit-center' }}
            >
              <input
                id="file"
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
              {qrCodeUrl && (
                <>
                  {loading ?
                    <svg aria-hidden="true" class="inline ml-4 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="gray" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="red" />
                    </svg>
                    :
                    <div>
                      <img
                        src={qrCodeUrl}
                        alt="Content"
                        className="my-4"
                        style={{
                          width: "auto",
                          height: "auto",
                          maxWidth: "200px",
                          maxHeight: "200px",
                          position: 'relative', // Ensure relative positioning for the image
                        }}
                      />
                    </div>
                  }
                </>
              )}
            </div>
            <div
              className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${openQrModal ? "" : "hidden"
                }`}
            >
              <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
                <div className="flex flex-col gap-5">
                  <div className="bg-background rounded-xl sm:p-8 p-5">
                    <div className="flex flex-col gap-4 mt-5">
                      <img
                        style={{ width: '100%', height: '400px', objectFit: 'contain' }}
                        src={checkFile}
                      />
                      <div className="text-right">
                        <Button
                          size="sm"
                          type="button"
                          className="w-50 px-12 bg-gray-300 text-gray-700 mr-4"
                          onClick={CloseModal}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          type="button"
                          className="w-50 px-12 bg-blue text-white text-gray-700 "
                          style={{ color: 'white' }}
                          onClick={() => SubmitFileChange()}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <Button size="sm" type="submit" className="w-fit px-12 ml-8">
            {t("update")}
            </Button>
          </div>
        </form>
      </section>
    </AppLayout>
  );
};

export default Settings;
