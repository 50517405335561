import { io } from "socket.io-client";

class WebSocketService {
    constructor() {
        this.socket = null;
    }

    connect(url, userId) {
        if (!this.socket) {
            this.socket = io(url, {
                reconnection: true, // Enable automatic reconnection
                reconnectionAttempts: Infinity, // Unlimited retries
                reconnectionDelay: 1000, // Initial delay between reconnection attempts
                reconnectionDelayMax: 5000, // Maximum delay between reconnections
            });

            this.socket.on("connect", () => {
                console.log("WebSocket connected!");
            });

            this.socket.on("disconnect", () => {
                console.log("WebSocket disconnected!");
            });

            this.socket.on("connect_error", (err) => {
                console.error("Connection error:", err);
            });
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }

    on(event, callback) {
        if (this.socket) {
            this.socket.on(event, callback);
        }
    }

    emit(event, data) {
        if (this.socket) {
            this.socket.emit(event, data);
        }
    }
}

const webSocketService = new WebSocketService();
export default webSocketService;
