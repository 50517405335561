import React, { Fragment, useEffect, useState } from "react";

// Components
import { MenuIcon, Global } from "../../assets/icons/Icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import useToken from "./../../hooks/useToken";
import interceptor, { baseUrl } from "../../hooks/intercepter";
import { DropdownMenuArrow } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from 'react-i18next';


const Topbar = ({ toggleSidebar, isSidebarOpen }) => {
  const [qrCode, SetQrCode] = useState("");
  const navigate = useNavigate();
  const { user } = useToken();
  const { token } = useToken();

  const { t, i18n } = useTranslation("translation");

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Change the language
  };

  useEffect(() => {
    if (user?.user_type === "Broadcaster") { fetchQrCode(); }
  }, []);

  const logout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const fetchQrCode = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/broadcaster/${user.id}/qrcode`,
        token
      );
      if (response.status) {
        SetQrCode(baseUrl + "media/" + response?.data?.data.filename);
      }
    } catch (error) {
      console.error("Failed to fetch content:", error);
    }
  };



  return (
    <Fragment>
      <header className="bg-background sticky top-0 z-20">
        <nav className="py-4 sm:px-[1.875rem] px-5 flex xl:justify-end justify-between items-center">

          <DropdownMenu>
            <DropdownMenuTrigger className="flex xl:justify-end justify-between items-center gap-1 mr-4">
              <Global className="text-blue" stroke="blue" width={30} height={30} />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-white rounded-xl ms-3">
              <DropdownMenuArrow className="fill-current text-white" />
              <DropdownMenuItem className="text-sm font-medium text-dark rounded-xl transition-all duration-500 hover:text-white hover:bg-blue cursor-pointer">
                <p onClick={() => changeLanguage('en')}>English</p>
              </DropdownMenuItem>
              <DropdownMenuItem className="text-sm font-medium text-dark rounded-xl transition-all duration-500 hover:text-white hover:bg-blue cursor-pointer">
                <p onClick={() => changeLanguage('fr')}>French</p>
              </DropdownMenuItem>
              <DropdownMenuItem className="text-sm font-medium text-dark rounded-xl transition-all duration-500 hover:text-white hover:bg-blue cursor-pointer">
                <p onClick={() => changeLanguage('es')}>Spanish</p>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <DropdownMenu>
            <DropdownMenuTrigger className="flex xl:justify-end justify-between items-center gap-2.5">
              {user?.user_type !== "Broadcaster" ? (
                <span className="text-sm font-medium uppercase text-white bg-blue w-8 h-8 rounded-full flex items-center justify-center">
                  {user?.first_name?.substring(0, 1)}
                </span>
              ) : (
                <img src={qrCode} className="w-8 h-8 rounded-full" alt="qr" />
                // <span className="text-sm font-medium uppercase text-white bg-blue w-8 h-8 rounded-full flex items-center justify-center">
                //   {user?.first_name?.substring(0, 1)}
                // </span>
              )}
              <p className="uppercase xl:block hidden">{user?.first_name}</p>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-white rounded-xl ms-3">
              {/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
              {/* <DropdownMenuItem className="text-sm font-medium text-dark rounded-xl transition-all duration-500 hover:text-white hover:bg-blue cursor-pointer">
                <p onClick={() => navigate("setting")}>My Account</p>
              </DropdownMenuItem>
              <DropdownMenuSeparator className="border border-background" /> */}
              <DropdownMenuItem className="text-sm font-medium text-dark rounded-xl transition-all duration-500 hover:text-white hover:bg-blue cursor-pointer">
                <p onClick={logout}>{t('logout')}</p>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <p className="uppercase xl:hidden block">{user?.first_name}</p>

          <button
            className="block xl:hidden xl:ms-5 bg-logo px-2 py-3 rounded-lg"
            onClick={toggleSidebar}
          >
            <MenuIcon width={24} height={11} stroke="#242331" />
          </button>
        </nav>
      </header>
      {!isSidebarOpen && (
        <div className="bg-black bg-opacity-75 fixed left-0 top-0 h-screen w-screen transition duration-400 z-20"></div>
      )}
    </Fragment>
  );
};

export default Topbar;
