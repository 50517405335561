import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const baseUrl = `${REACT_APP_BASE_URL}`;

const interceptor = {
  axiosPost: (urlPath, data, token) => {
    return axios({
      method: "Post",
      url: `${baseUrl}${urlPath}`,
      data: data,
      // data: new URLSearchParams(data),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  },

  axiosPatch: (urlPath, data, token) => {
    return axios({
      method: "Patch",
      url: `${baseUrl}${urlPath}`,
      data: data,
      // data: new URLSearchParams(data),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        debugger
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  },

  axiosPostWithoutToken: (urlPath, data) => {
    return axios({
      method: "Post",
      url: `${baseUrl}${urlPath}`,
      data: data,
      // data: new URLSearchParams(data),
      headers: {
        Authorization: null,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  },
  axiosPostFormData: (urlPath, data, token) => {
    return axios({
      method: "Post",
      url: `${baseUrl}${urlPath}`,
      data: data,
      // data: new URLSearchParams(data),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  },
  axiosFormData: ( method, urlPath, data, token) => {
    return axios({
      method: method,
      url: `${baseUrl}${urlPath}`,
      data: data,
      // data: new URLSearchParams(data),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      responseType: "blob",
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  },
  axiosPut: (method, urlPath, data, token) => {
    return axios({
      method: method,
      url: `${baseUrl}${urlPath}`,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      // headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  },

  axiosGet: (method, urlPath, token, data) => {
    return axios({
      method: method,
      url: `${baseUrl}${urlPath}`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  },

  axiosGetWithParams: (method, urlPath, token, data) => {
    return axios({
      method: method,
      url: `${baseUrl}${urlPath}`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      params: data,
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response.data;
      });
  },

  axiosDelete: (method, urlPath, data, token) => {
    return axios({
      method: method,
      url: `${baseUrl}${urlPath}`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) throw err.response.data;
        else if (err.request) throw err.request;
        else throw new Error(`Error: ${err.message}`);
      });
  },
};

export default interceptor;
