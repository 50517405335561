import React, { Fragment } from "react";

// Components
import {
  CalendarIcon,
  ElementPlusIcon,
  HeadphoneIcon,
  SemiCircleLightBlue,
  SemiCircleYellow,
} from "../../../../assets/icons/Icons";

// Assets
import aboutImg from "../../../../assets/images/about-img1.png";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation("translation");
  return (
    <Fragment>
      <section className="bg-background md:py-20 py-10">
        <div className="md:container mx-auto px-5">
          <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-0 gap-16">
            <div className="lg:col-span-5 col-span-full relative">
              <SemiCircleYellow
                fill="#D5FC71"
                width={80}
                height={220}
                className="absolute opacity-100 -bottom-28 left-1/2 -translate-x-1/2 transform rotate-90 md:w-20 w-16"
              />
              <img
                src={aboutImg}
                width={535}
                height={700}
                className="block object-cover lg:w-auto w-full rounded-xl"
                alt=""
              />
              <SemiCircleLightBlue
                fill="#23E3EE"
                width={49}
                height={100}
                className="absolute opacity-100 -top-5 left-7 "
              />
            </div>
            <div className="lg:col-start-7 lg:col-span-5 col-span-full flex flex-col justify-center">
              <h2 className="sm:text-4xl text-3xl font-playfair font-bold uppercase ">
               {t("section4Head")}
              </h2>
              <p className="text-base font-medium sm:mt-5 mt-3">
               {t("section4Detail")}
              </p>
              <ul className="flex flex-col gap-5 lg:mt-20 mt-10">
                <li className="flex items-center gap-4 border-b border-blue border-opacity-20 py-2.5 w-fit">
                  <ElementPlusIcon
                    fill="#503AC9"
                    width={24}
                    height={24}
                    className="flex-shrink-0 flex-grow-0"
                  />
                  <p className="sm:text-lg text-sm font-medium">
                  {t("section4Detail1")}
                  </p>
                </li>
                <li className="flex items-center gap-4 border-b border-blue border-opacity-20 py-2.5 w-fit">
                  <CalendarIcon
                    fill="#503AC9"
                    width={24}
                    height={24}
                    className="flex-shrink-0 flex-grow-0"
                  />
                  <p className="sm:text-lg text-sm font-medium">
                  {t("section4Detail2")}
                  </p>
                </li>
                <li className="flex items-center gap-4 border-b border-blue border-opacity-20 py-2.5 w-fit">
                  <HeadphoneIcon
                    fill="#503AC9"
                    width={24}
                    height={24}
                    className="flex-shrink-0 flex-grow-0"
                  />
                  <p className="sm:text-lg text-sm font-medium">
                  {t("section4Detail3")}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;
