import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import PaymentForm from "../PaymentForm"; // adjust path as needed
import { Input } from "../../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Button } from "../../../../components/ui/button";
import Layout from "../../../../components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import interceptor from "../../../../hooks/intercepter";
import useToken from "../../../../hooks/useToken";
import toast from "react-hot-toast";
import { GalleryIcon } from "../../../../assets/icons/Icons";
import Loader from "../../../../components/ui/Loader";
import { EditorState, convertFromRaw, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import heic2any from "heic2any";
import { useTranslation } from "react-i18next";

const StepOne = (prop) => {
  const { t,ready } = useTranslation("translation");
  const { onSelectedFile, maxDuration, item } = prop
  const navigate = useNavigate();
  const token = useToken();
  const [id, setId] = useState();
  const [advertiserId, setAdvertiserId] = useState();
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [terms, setTerms] = useState(false);
  const [title, setTitle] = useState("");
  const [contentId, setContentId] = useState("");

  const [clientIntent, setClientIntent] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    package: "",
  });
  const [clientSecret, setClientSecret] = useState(null);
  const [duration, setDuration] = useState();
  // const userId = window.location.pathname.split("/")[3];

  const [contentType, setContentType] = useState('image');
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const plainText = content.getCurrentContent().getPlainText();
  const remainingChars = 200 - plainText.length;
  const isLimitReached = remainingChars <= 0;

  const options = {
    clientSecret: clientSecret,
  };



  // useEffect(() => {
  //   if (id) setError(false);
  //   if (file) setError(false);
  //   if (formData.email) setError(false);
  //   if (terms) setError(false);
  // }, [id, file, formData.email, terms]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      package: id,
    }));
  };



  const generateVideoThumbnail = async (videoFile, durations) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.currentTime = 1;
    onSelectedFile({
      file: videoFile,
      type: videoFile.type,
      name: videoFile.name,
      title: videoFile.name,
      videoThumbnail: null,
      imagePreview: null,
      duration: durations
    })
    video.onloadeddata = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      await setVideoThumbnail(thumbnailUrl);
      setImagePreview(null);
      onSelectedFile({
        file: videoFile,
        type: videoFile.type,
        name: videoFile.name,
        title: videoFile.name,
        videoThumbnail: thumbnailUrl,
        imagePreview: null,
        duration: durations
      })
    };
  };

  const handleFileChange = async (e) => {
    setError(false);
    setErrorMessage('')
    // if (!duration) {
    //   setError(true);
    //   setErrorMessage(`Please select package first.`);
    //   return;
    // }
    const selectedFile = e.target.files[0];
    setImagePreview(null)
    debugger
    if (selectedFile.type.includes("video")) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(selectedFile);

      video.onloadedmetadata = async () => {
        debugger
        setDuration(video.duration);
        console.log("duration", video.duration)
        if (video.duration > duration) {
          setError(true);
          setErrorMessage(
            `Please select a video that is no longer than ${duration} seconds.`
          );
          setFile(null);
          setFileType("");
          setFileName("");
          setVideoThumbnail(null);
          setImagePreview(null);
          e.target.value = null;
        } else {
          setFile(selectedFile);
          setFileType(selectedFile.type);
          setFileName(selectedFile.name);
          setTitle(selectedFile.name);

          await generateVideoThumbnail(selectedFile, video.duration);


        }
      };
    } else if ((selectedFile.type.includes("image"))) {
      setFile(selectedFile);
      setFileType(selectedFile.type);
      setFileName(selectedFile.name);
      setTitle(selectedFile.name);
      var previewUrl = null
      if (selectedFile.type === "image/heic") {
        const convertedBlob = await heic2any({
          blob: selectedFile,
          toType: "image/jpeg", // or "image/png"
        });

        previewUrl = URL.createObjectURL(convertedBlob);
        setImagePreview(previewUrl);
      } else {
        previewUrl = URL.createObjectURL(selectedFile);
        setImagePreview(URL.createObjectURL(selectedFile));
      }
      setVideoThumbnail(null);
      onSelectedFile({
        file: selectedFile,
        type: selectedFile.type.split("/")[0],
        name: selectedFile.name,
        title: selectedFile.name,
        videoThumbnail: null,
        imagePreview: previewUrl,
        duration: 0
      })
    }


  };
  const TextChngae = (e) => {
    setContent(e);
    const rawContentState = draftToHtml(convertToRaw(content.getCurrentContent()));
    setFile(null);
    setFileType("");
    setFileName("");
    setVideoThumbnail(null);
    onSelectedFile({
      file: plainText ? JSON.stringify(rawContentState) : null,
      type: 'text',
      name: null,
      title: null,
      videoThumbnail: null,
      imagePreview: null,
      duration: isLimitReached
    })
  }

  useEffect(() => {
    debugger
    if (item?.videoThumbnail) setVideoThumbnail(item?.videoThumbnail);
    if (item?.imagePreview) setImagePreview(item?.imagePreview);
    if (item?.name) setFileName(item?.name);
    if (item?.file) setFile(item?.file);
  }, []);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section>
            <div className="md:container mx-auto px-5">
              <div className="lg:w-6/12 w-full mx-auto">
                <div className="flex flex-col items-center gap-2">
                  <h4 className="md:text-3xl text-2xl font-bold text-dark text-center">
                    {t("prendsLe")} <span className="text-blue"> {t("controle")} </span>  {t("deCetEcran")}
                  </h4>
                  <p className="text-sm mt-2 text-center">
                    {t("tuSouhaitesPartager")}
                    <Link href="#" className="text-blue ml-1">
                      {t("selfie")}
                    </Link>
                    ?
                    <Link href="#" className="text-blue ml-1">
                      {t("TesReseauxSociaux")}
                    </Link>
                    {t("afficherUnMessage")}
                    <Link href="#" className="text-blue mx-1">
                      {t("message")}
                    </Link>
                    {t("devantToutLeMonde")}
                  </p>
                  <p className="text-sm text-center">
                    {t("selectionnePhotoOuVideo")} {maxDuration} {t("selectionnePhotoOuVideo2")}
                  </p>
                </div>
                <div className="bg-background rounded-xl md:p-12 p-6   md:pt-4  flex flex-col gap-5 mt-5">
                  {/* <div className="mt-4">
                    <select
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border border-dark-gray"
                      value={contentType}
                      onChange={(e) => setContentType(e.target.value)}
                    >
                      <option value="image">Image/Video</option>
                      <option value="text">Text</option>
                    </select>
                  </div> */}

                  {/* {(contentType == 'video' || contentType == 'image') &&
                    <> */}

                  {!plainText &&
                    <>
                      <label
                        htmlFor="picture"
                        className="flex flex-col items-center gap-8 px-8 py-12 border border-dashed border-blue bg-background rounded-xl sm:px-12 md:py-16 bg-white"
                      >
                        {videoThumbnail ? (
                          <img
                            src={videoThumbnail}
                            alt="Video Thumbnail"
                            className="object-cover w-10 h-10 sm:w-14 sm:h-14 rounded-xl"
                          />
                        ) : imagePreview ? (
                          <img
                            src={imagePreview}
                            alt="Img Preview "
                            className="object-cover w-10 h-10 sm:w-14 sm:h-14 rounded-xl"
                          />
                        ) : (
                          <GalleryIcon
                            width={60}
                            height={60}
                            className="w-10 h-10 sm:w-14 sm:h-14"
                            stroke="#242331"
                          />
                        )}
                        <Input
                          id="picture"
                          className="hidden"
                          type="file"
                          onChange={handleFileChange}
                          accept="image/*,video/* ,.heic "
                        />
                        <h6 className="text-lg text-center">
                          {file ? (
                            <span>{fileName}</span>
                          ) : (
                            <>
                              {t("faitesGlisserDeposez")}  {" "}
                              <span className="font-semibold text-blue">
                                {t("parcourez")}
                              </span>
                            </>
                          )}
                        </h6>
                      </label>
                      {uploadProgress > 0 && (
                        <div className="mt-4">
                          <progress
                            value={uploadProgress}
                            max="100"
                            className="w-full"
                          ></progress>
                          <span>{uploadProgress}%</span>
                        </div>
                      )}
                    </>}
                  {/* </>
                  // }
                  // {(contentType == 'url' || contentType == 'text') &&
                    <> */}
                  <div className="mt-4 rounded-xl p-1 rounded-full border border-dark-gray bg-white p-2">
                    <Editor
                     key={t("editorPlaceholder")} 
                      className="p-0"
                      wrapperClassName="demo-wrapper"
                      placeholder={t("editorPlaceholder") || "Start typing..."}
                      editorClassName="demo-editor"
                      editorState={content}
                      onEditorStateChange={TextChngae}
                      toolbar={{
                        options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        history: { inDropdown: true },
                      }
                      }
                    />
                    {content && <p className="text-xs text-gray-500 mt-1">
                      {remainingChars} characters remaining
                    </p>
                    }
                  </div>
                  {/* </>
                  } */}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default StepOne;
