import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Components
import { Input } from "../../../components/ui/input";
import {
  DeleteIcon,
  GalleryIcon,
  ViewIcon,
  VideoIcon,
  CloseIcon,
} from "../../../assets/icons/Icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import AppLayout from "../../../components/applayout/AppLayout";
import Loader from "../../../components/ui/Loader";
import useToken from "../../../hooks/useToken";
import draftToHtml from 'draftjs-to-html';

// Assets
import tableImg from "../../../assets/images/hero-img.png";

// Interceptor
import interceptor from "../../../hooks/intercepter.js";
import ReactPaginate from "react-paginate";
import { ImageIcon, PlayIcon, SidebarCloseIcon, Video } from "lucide-react";
import { baseUrl } from "../../../hooks/intercepter.js";
import toast from "react-hot-toast";
import { FacebookEmbed, InstagramEmbed, TikTokEmbed, XEmbed } from "react-social-media-embed";
import { contentEventEmitter } from "./../../../hooks/event-emitter";
import StatusToggle from "../../admin/users/StatusToggle";
import { useTranslation } from "react-i18next";
import { Select } from "@radix-ui/react-select";
// import {
//   BtnBold,
//   BtnBulletList,
//   BtnClearFormatting,
//   BtnItalic,
//   BtnNumberedList,
//   BtnStyles,
//   Editor,
//   EditorProvider,
//   HtmlButton,
//   Toolbar,

// } from 'react-simple-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import heic2any from "heic2any";
import { useWebSocket } from "../../../context/WebSocketContext";
const AddContent = () => {
  const { t } = useTranslation("translation");
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [title, setTitle] = useState("");
  const [type, setTyoe] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [contentList, setContentList] = useState([]);
  const [contentType, setContentType] = useState('image');
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [contentUrl, setContentUrl] = useState('');
  const [contentListByAdvertiser, setContentListByAdvertiser] = useState([]);
  const { token, removeToken, user } = useToken();
  const webSocketService = useWebSocket();
  const [itemOffset, setItemOffset] = useState(0);
  const [itemOffset1, setItemOffset1] = useState(0);
  const itemsPerPage = 6;
  const endOffset = itemOffset + itemsPerPage;
  const endOffset1 = itemOffset1 + itemsPerPage;
  let filteredItems = contentList.filter((item) => item.content_type !== "qr_code");

  const currentItems = filteredItems.slice(itemOffset, endOffset);
  const ItemsByAdvertiser = contentListByAdvertiser.slice(
    itemOffset1,
    endOffset1
  );
  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
  const pageCountByAdvertiser = Math.ceil(
    contentListByAdvertiser.length / itemsPerPage
  );

  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [broadcastduration, setBroadcastduration] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setCurrentPage(event.selected);
    setItemOffset(newOffset);

  };

  const handlePageClickByAdvertiser = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset1(newOffset);
  };

  useEffect(() => {
    // Emit a message when the component mounts

  }, [webSocketService]);

  console.log("currentItems", currentItems);

  useEffect(() => {
    fetchContent();
    getChangeStatus();
    getFileDuration()
    // fetchContentByAdvertiser();
  }, []);


  const fetchContent = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/combined-content/${user.id}`,
        token
      );
      console.log(response?.data?.data?.result);
      if (response.status) {
        setContentList(response?.data?.data?.result);
        // toast.success("Content fetched successfully");
      }
    } catch (error) {
      console.error("Failed to fetch content:", error);
      toast.error("Failed to fetch content");
    }
  };

  const fetchContentByAdvertiser = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        "advertiser/content",
        null
      );
      console.log(response.data.data.result);
      if (response.status) {
        setContentListByAdvertiser(response.data.data.result);
        // toast.success("Content fetched successfully");
      }
    } catch (error) {
      console.error("Failed to fetch contentsdfsdf:", error);
      toast.error("Failed to fetch contentasfdsfsdf");
    }
  };

  const handleDelete = async (id) => {
    webSocketService.emit("server_message", {
      message: "delete",
    });
    try {
      const response = await interceptor.axiosDelete(
        "DELETE",
        `broadcaster/content/${id}`,
        null,
        token
      );
      console.log("Delete successful:", response.data);
      contentEventEmitter.emit("contentUpdated");
      fetchContent();
      toast.success("Content deleted successfully");
    } catch (error) {
      console.error("Delete failed:", error);
      toast.error("Failed to delete content");
      if (error.status === 401) {
        removeToken();
      }
    }
  };


  const getFileDuration = (value) => {
    console.log("sidbar")
    try {
      interceptor.axiosGet("get", `packages/content-duration`).then((res) => {
        if (res?.status === 200) {
          console.log(res?.data?.data?.content_duration)
          setBroadcastduration(res?.data?.data?.content_duration);
        }

      });

    } catch (error) {
      console.error("Failed to fetch packages:", error);
    }
  }

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);
    if (selectedFile.type.startsWith("video/")) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(selectedFile);

      video.onloadedmetadata = () => {
        if (video.duration > broadcastduration) {
          alert(`Please select a video that is no longer than ${broadcastduration} seconds.`);
          setFile(null);
          setFileType("");
          setFileName("");
          setTitle("");
          setVideoThumbnail(null);
          setImagePreview(null);
          e.target.value = null;
        } else {
          setFile(selectedFile);
          setFileType(selectedFile.type);
          setFileName(selectedFile.name);
          setTitle(selectedFile.name);
          generateVideoThumbnail(selectedFile);
        }
      };
    } else if (selectedFile.type.startsWith("image/") || selectedFile.name.split('.').pop() == 'heic') {
      setFile(selectedFile);
      setFileType(selectedFile.type);
      setFileName(selectedFile.name);
      setTitle(selectedFile.name);

      if (selectedFile.type === "image/heic" || selectedFile.name.split('.').pop() == 'heic') {
        const convertedBlob = await heic2any({
          blob: selectedFile,
          toType: "image/jpeg", // or "image/png"
        });

        const previewUrl = URL.createObjectURL(convertedBlob);
        setImagePreview(previewUrl);
      } else {
        setImagePreview(URL.createObjectURL(selectedFile));
      }
      setVideoThumbnail(null);
    }
  };

  const generateVideoThumbnail = (videoFile) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.currentTime = 1;

    video.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      setVideoThumbnail(thumbnailUrl);
      setImagePreview(null);
    };
  };

  const plainText = content.getCurrentContent().getPlainText();
  const remainingChars = 200 - plainText.length;
  const isLimitReached = remainingChars <= 0;
  const handleUpload = async () => {

    if ((contentType == 'video' || contentType == 'image') && !file) {
      alert("Please select a file first!");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("content_type", contentType);
    formData.append("title", title);
    if (contentType == 'text') {

      if (!isLimitReached) {
        const rawContentState = draftToHtml(convertToRaw(content.getCurrentContent()));
        formData.append("content", JSON.stringify(rawContentState));
      }
      else {
        setLoading(true);
        alert("You Can only enter 200 characters");
        return;
      }
    }
    else if (contentType == 'url') {
      if (contentUrl) {
        formData.append("content", contentUrl);
      } else {
        setLoading(true);
        alert("Plase add url");
        return;
      }
    }
    else {
      formData.append("file", file);
    }

    try {
      const response = await interceptor.axiosPostFormData(
        "broadcaster/content",
        formData,
        token,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      if (response?.data?.status === true) {
        setLoading(false);
        toast.success("Upload successful");
      } else {
        setLoading(false);
        toast.error(response?.message);
      }
      fetchContent();
      setFile(null);
      setFileType("");
      setFileName("");
      setTitle("");
      setVideoThumbnail(null);
      setImagePreview(null);
      setUploadProgress(0);
      setShowModal(false);
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error(error?.message);
    }
  };

  const handleConfirmDelete = (id) => {

    setDeleteId(id);
    setShowModalDelete(true);
  };

  const handleModalClose = () => {
    setShowModalDelete(false);
    setDeleteId(null);
  };

  const handleModalConfirm = () => {
    handleDelete(deleteId);
    handleModalClose();
  };

  const handleAddContentClick = () => {
    setFile(null);
    setFileType("");
    setFileName("");
    setTitle("");
    setVideoThumbnail(null);
    setImagePreview(null);
    setUploadProgress(0);
    setShowModal(true);
  };

  const handleViewContent = (content) => {
    setSelectedContent(content);
    setShowContentModal(true);
  };

  const handleCloseContentModal = () => {
    setShowContentModal(false);
    setSelectedContent(null);
  };
  const [status, setStatus] = useState();
  const handleChangeStatus = async () => {
    try {
      const response = await interceptor.axiosPut("Post", `users/broadcaster/qrcode`,
        {
          is_qrcode_show: !status,
          user_id: user?.id
        }, token
      );
      if (response.status === 200) {
        setStatus(response?.data.data.is_qrcode_show)
        getChangeStatus();
        fetchContent();
        toast.success("Status Updated Successfully");
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Update request failed:", error);
    }
  };

  const getChangeStatus = async () => {
    try {
      const response = await interceptor.axiosGet("Get", `users/broadcaster/qrcode?user_id=${user?.id}`,
        token
      );

      if (response.status === 200) {
        setStatus(response?.data.data.is_qrcode_show)
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Update request failed:", error);
    }
  };

  const iframeRef = useRef(null);
  useEffect(() => {
    const handleAutoplay = () => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        // Attempt to send a message to the iframe
        iframeRef.current.contentWindow.postMessage({ method: "play" }, "*");
      }
    };

    // Wait for the iframe to load before trying to autoplay
    const iframeElement = iframeRef.current;
    if (iframeElement) {
      iframeElement.onload = handleAutoplay;
    }

    // Clean up event listener on unmount
    return () => {
      if (iframeElement) {
        iframeElement.onload = null;
      }
    };
  }, []);

  return (
    <AppLayout>
      {loading ? (
        <Loader content="Uploading" />
      ) : (
        <>
          {/* <ToastContainer /> */}
          <section className="mb-20 sm:mb-24">

            <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
              {t("addContent")}
            </h5>
            <div className="flex">
              <button
                onClick={handleAddContentClick}
                className="bg-blue rounded-xl text-white text-sm font-medium px-8 py-3.5"
              >
                {t("addContent")}
              </button>
              <div className="px-8 w-fit valition ml-4">
                <span className="px-3 pt-1 font-medium text-sm" style={{ verticalAlign: 'middle' }}> {t("enableQr")}</span>
                <StatusToggle
                  isActive={status}
                  onToggle={() => handleChangeStatus()}
                />
              </div>
            </div>

            <div className="mt-6 overflow-x-auto sm:mt-8 whitespace-nowrap">
              <div className="w-full overflow-auto">
                <Table className="w-full overflow-hidden border border-light-gray rounded-xl">
                  <TableHeader className="font-semibold">
                    <TableRow>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-semibold">{t("number")}</h6>
                      </TableHead>
                      <TableHead className="p-3 text-left" style={{ width: "100px" }}>
                        <h6 className="text-sm font-semibold" style={{ width: "100px" }}>{t("content")}</h6>
                      </TableHead>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-semibold">{t("type")}</h6>
                      </TableHead>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-normal">
                          {t("title")}
                        </h6>
                      </TableHead>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-normal">
                          {t("source")}
                        </h6>
                      </TableHead>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-normal">  {t("action")}</h6>
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentItems.map((currentContent, index) =>
                      currentContent.content_type === "qr_code" ? null : (
                        <TableRow
                          key={currentContent.id}
                          className="align-middle"
                        >
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                            {(currentPage * itemsPerPage) + index + 1}
                          </TableCell>
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap" style={{ width: "100px" }}>
                            {currentContent?.content_type === "mov" ||
                              currentContent?.content_type === "mp4" ||
                              currentContent?.content_type === "url" ||
                              currentContent?.content_type === "gif" ? (
                              <VideoIcon
                                width={60}
                                height={60}
                                className="block object-cover rounded-xl w-[3.75rem] h-[3.75rem] flex-shrink-0 flex-grow-0"
                                stroke="#242331"
                              />
                            ) :
                              (
                                currentContent?.content_type !== "text" ?

                                  <img
                                    className="w-16 h-16 rounded-md"
                                    src={
                                      baseUrl +
                                      "media/" +
                                      currentContent.content_path
                                    }
                                    alt={currentContent.title}
                                  />
                                  : (

                                    <div style={{ width: "150px", whiteSpace: "normal", overflowWrap: "break-word" }}>
                                      {(() => {
                                        try {
                                          const cleanedContentPath = currentContent.content_path?.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '');
                                          const parsedContent = JSON.parse(cleanedContentPath);
                                          if (typeof parsedContent === 'string') {
                                            return parsedContent.length > 50 ? parsedContent.slice(0, 50) + '...' : parsedContent;
                                          }
                                          return JSON.stringify(parsedContent);
                                        } catch (error) {
                                          console.error('Invalid JSON in content_path:', error);
                                          return currentContent.content_path?.replace(/<\/?[^>]+(>|$)/g, '');
                                        }
                                      })()}
                                    </div>
                                  )
                              )
                            }
                          </TableCell>
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                            {currentContent.content_type}
                          </TableCell>
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                            {currentContent.title}
                          </TableCell>
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                            {currentContent.source === "advertiser"
                              ? t("advertiser")
                              :  t("broadcaster")}
                          </TableCell>
                          <TableCell className="text-right py-2.5 flex items-center align-middle gap-2.5 h-14 pr-5">
                            <button
                              onClick={() => handleViewContent(currentContent)}
                              className="flex items-center justify-center w-6 h-6 rounded-md text-white bg-blue"
                            >
                              <ViewIcon className="w-5 h-5" />
                            </button>

                            {/* {currentContent.source !== "advertiser" && ( */}
                              <Link
                                to="#"
                                className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                              >
                                <button
                                  onClick={() =>
                                    handleConfirmDelete(currentContent.id)
                                  }
                                  className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                                >
                                  <DeleteIcon
                                    stroke="white"
                                    width={14}
                                    height={14}
                                  />
                                </button>
                              </Link>
                            {/* )} */}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="mt-6 sm:mt-8">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Suivant >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={6}
                pageCount={pageCount}
                previousLabel="< Précédent"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          </section>
          <div
            className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showModal ? "" : "hidden"

              }`}
          >
            <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">

              <div className="flex flex-col gap-5">
                <div className="bg-background rounded-xl sm:p-8 p-5">
                  <h3 className="text-xl font-semibold">{t("addContent")}</h3>
                  <div className="mt-4">
                    <select
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border border-dark-gray"
                      value={contentType}
                      onChange={(e) => setContentType(e.target.value)}
                    >
                      <option value="image">Image/Video</option>
                      <option value="text">Text</option>
                      {/* <option value="url">Url</option> */}
                    </select>
                  </div>

                  {(contentType == 'video' || contentType == 'image') &&
                    <>
                      <div className="mt-4">
                        <input
                          type="file"
                          accept="image/*,video/*,.heic"
                          onChange={handleFileChange}
                        />
                      </div>

                      {file && (
                        <div className="mt-4">
                          <Input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Title"
                          />
                        </div>
                      )}
                      {file && (
                        <div className="mt-4">
                          {fileType.startsWith("video/") ? (
                            <video
                              className="w-full h-64"
                              controls
                              src={URL.createObjectURL(file)}
                            />
                          ) : (
                            <img
                              className="w-full h-64"
                              src={imagePreview}
                              alt={fileName}
                            />
                          )}
                        </div>
                      )}
                      {file && uploadProgress > 0 && (
                        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                          <div
                            className="bg-blue-600 h-2.5 rounded-full"
                            style={{ width: `${uploadProgress}%` }}
                          />
                        </div>
                      )}
                    </>

                  }
                  {(contentType == 'text') &&
                    <>
                      <div className="mt-4 rounded-xl p-1 rounded-full border border-dark-gray bg-white p-2">
                        <Editor
                        key={t("editorPlaceholder")} 
                          className=""
                          placeholder={t("editorPlaceholder") || "Start typing..."}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          editorState={content}
                          onEditorStateChange={setContent}
                          toolbar={{
                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            history: { inDropdown: true },
                          }
                          }
                        />
                        {content && <p className="text-xs text-gray-500 mt-1">
                          {remainingChars} characters remaining
                        </p>
                        }
                      </div>
                    </>

                  }
                  {(contentType == 'url') &&
                    <>
                      <div className="mt-4 rounded-xl p-1 rounded-full border border-dark-gray">
                        <Input
                          value={contentUrl}
                          onChange={(e) => setContentUrl(e.target.value)}
                          placeholder="Please Enter Url"
                        />
                      </div>
                    </>

                  }
                  <div className="mt-6 flex justify-end">
                    <button
                      onClick={() => setShowModal(false)}
                      className="p-2 text-white rounded bg-gray-600"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleUpload}
                      className="p-2 ml-2 text-white rounded bg-blue"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div
            className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showContentModal ? "" : "hidden"

              }`}
          >
            <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">

              <div className="flex flex-col gap-5">
                <div className="bg-background rounded-xl sm:p-8 p-5">
                  <div className="flex justify-between items-start">
                    <h3 className="text-xl font-semibold mb-4">
                      {selectedContent?.title}
                    </h3>
                    {/* <p>dfghj</p> */}
                    <div className="h-3">
                      <button
                        onClick={handleCloseContentModal}
                        className=" text-4xl text-gray-500 hover:text-gray-800 p-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-square-x"
                        >
                          <rect
                            width="18"
                            height="18"
                            x="3"
                            y="3"
                            rx="2"
                            ry="2"
                          />
                          <path d="m15 9-6 6" />
                          <path d="m9 9 6 6" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="h-[400px] overflow-scroll">

                    <div id="tiktok-video">
                      {/* <iframe
    src="https://www.tiktok.com/embed/7423279464110443794"
    width="325"
    height="575"
    frameborder="0"
    allow="autoplay; encrypted-media"
    allowfullscreen>
  </iframe> */}
                      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
    <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fmemesbyshohab%2Fvideos%2F1064060261613963%2F&show_text=false&width=377&t=0"
      width="377" height="350" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
  </div>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <InstagramEmbed url="https://www.instagram.com/reel/DBeOWZRMSGK/?utm_source=ig_web_copy_link" width={328} />
   
  </div>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <TikTokEmbed url="https://www.tiktok.com/@pkdarama001/video/7374649382055972129?is_from_webapp=1&sender_device=pc" width={325} />
  </div>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <XEmbed url="https://twitter.com/PixelAndBracket/status/1356633038717923333" width={325} />
  </div> */}

                    </div>

                    {selectedContent?.content_type === "mov" ||
                      selectedContent?.content_type === "mp4" ||
                      selectedContent?.content_type === "gif" ? (
                      <video
                        className="w-full h-full"
                        controls
                        src={baseUrl + "media/" + selectedContent?.content_path}
                      />
                    ) : selectedContent?.content_type === "text" ? (
                      <div dangerouslySetInnerHTML={{ __html: JSON.parse(selectedContent?.content_path) }}></div>
                    ) : selectedContent?.content_type === "url" ? (
                      <div>
                        <div id="tiktok-video">
                          {/* <iframe
    src="https://www.tiktok.com/embed/7423279464110443794"
    width="325"
    height="575"
    frameborder="0"
    allow="autoplay; encrypted-media"
    allowfullscreen>
  </iframe> */}
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {selectedContent?.content_path.includes('facebook') &&
                              <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fmemesbyshohab%2Fvideos%2F1064060261613963%2F&show_text=false&width=377&t=0"
                                width="377" height="350" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                            }
                            {selectedContent?.content_path.includes('instagram') &&
                              <InstagramEmbed width={328} />

                            }
                            {selectedContent?.content_path.includes('tiktok') &&
                              <iframe
                                src="https://www.tiktok.com/embed/7423279464110443794"
                                width="325"
                                height="575"
                                frameborder="0"
                                allow="autoplay; encrypted-media"
                                allowfullscreen>
                              </iframe>
                              //  <TikTokEmbed url="https://www.tiktok.com/@epicgardening/video/7055411162212633903" width={325} />
                              //  <TikTokEmbed ref={iframeRef}ƒ url={selectedContent?.content_path} width={325} allow="autoplay"/>
                            }
                            {selectedContent?.content_path.includes('twitter') &&
                              <XEmbed url="https://twitter.com/PixelAndBracket/status/1356633038717923333" width={325} />
                            }
                          </div>
                        </div>
                        {/* <div id="tiktok-video">
      <iframe
        src="https://www.tiktok.com/embed/7423279464110443794"
        width="325"
        height="575"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen>
      </iframe>
    </div> */}
                      </div>
                    ) : (
                      <img
                        className="w-full h-auto"
                        src={baseUrl + "media/" + selectedContent?.content_path}
                        alt={selectedContent?.title}
                      />
                    )}

                  </div>
                  <div className="mt-6 flex justify-end">
                    <button
                      onClick={() => setShowContentModal(false)}
                      className="p-2 text-white rounded bg-gray-600"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showModalDelete && (
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
              <div className="w-full max-w-lg p-6 bg-white rounded-lg">
                <h3 className="mb-4 text-lg font-semibold">Confirm Deletion</h3>
                <p className="mb-4">Are you sure you want to delete this Content?</p>
                <div className="flex justify-end gap-4">
                  <button
                    onClick={handleModalClose}
                    className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleModalConfirm}
                    className="px-4 py-2 text-white bg-red-600 rounded bg-red"
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          )}

        </>
      )}
    </AppLayout>
  );
};

export default AddContent;
