import React, { Fragment } from "react";

// Components
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { SendIcon } from "../../../../assets/icons/Icons";
import { Copy, CopyIcon } from "lucide-react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation("translation");
  const handleCopy = async () => {
    try {
      await window.navigator.clipboard.writeText("infos@speedfame.net");
      toast.success("Copied to clipboard!");
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
    }
  };
  return (
    <Fragment>
      <section className="bg-blue bg-newsletter bg-cover w-full h-full py-9">
        <div className="md:container mx-auto px-5">
          <div className="flex sm:flex-row flex-col items-center justify-between gap-6">
            <h1 className="lg:text-6.6xl md:text-4xl text-3.3xl font-playfair font-bold text-white sm:text-start text-center">
              {/* Contactez-nous en remplissant le formulaire ci-dessous <br></br> ou appelez-nous maintenant */}
              {/* <strong className="text-yellow">newsletter</strong> */}
              {t("footer1")}<br /> {t("footer2")}
            </h1>

            <div className="flex flex-col gap-5 max-w-72 w-full">
              <p className="text-sm font-medium text-yellow sm:text-start text-center">
                {t("footerInfo")}
              </p>
              <div className="relative flex">
                <div className="text-black flex justify-between items-center -mt-1 px-4 py-3 mb-0 font-normal bg-white rounded-xl w-full border-none placeholder:text-dark placeholder:text-opacity-50">
                  <p>infos@speedfame.net</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-copy"
                    cursor="pointer"
                    onClick={handleCopy}
                  >
                    <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
                    <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                  </svg>
                </div>
                {/* <Button className="bg-dark flex items-center justify-center rounded-tr-xl rounded-br-xl rounded-bl-xl rounded-tl-none w-[3.375rem] h-[3.375rem] absolute top-0 right-0"> */}
                {/* <CopyIcon fill="white" width={27} height={27} /> */}
                {/* </Button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Newsletter;
