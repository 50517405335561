import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  BroadcastIcon,
  CancelIcon,
  DeleteIcon,
  Edit,
  NoUserIcon,
  SearchIcon,
} from "../../../assets/icons/Icons";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import AppLayout from "../../../components/applayout/AppLayout";
import useToken from "../../../hooks/useToken"; // Import the useToken hook
import interceptor from "../../../hooks/intercepter.js";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../../components/ui/form";
import { Textarea } from "../../../components/ui/textarea";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";


const AddScreens = () => {
  const { t } = useTranslation("translation");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDurModal, setShowDurModal] = useState(false);
  const [screenId, setScreenId] = useState(null);
  const FormSchema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    location_name: z.string().min(1, { message: "Screen Code is required" }),
    location_address: z.string().min(1, { message: "Screen address is required" }),
  });
  const navigate = useNavigate();
  const { token, removeToken, user } = useToken(); // Use the useToken hook

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items?.length / itemsPerPage);
  const [name, setName] = useState();
  const [location, setLocation] = useState();
  const [address, setAddress] = useState();
  const socketRef = useRef();
  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      location_name: "",
      location_address: "",
    },
  });
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  console.log("currentItems", currentItems);
  useEffect(() => {
    fetchScreenList();
  }, []);


  useEffect(() => {
    // socketRef.current = io("https://coral-app-92lz5.ondigitalocean.app");
    socketRef.current = io("https://socket.speedfame.fr");
    console.log("Attempting to connect...");
    socketRef.current.on("server_message", (data) => {
      console.log(
        `Message from server: ${data.message.data}, is_screen_connected: ${data.message.is_screen_connected}`
      );
    });

    return () => {
      socketRef.current.disconnect();
      console.log("Disconnected from WebSocket server");
    };
  }, []);

  const fetchScreenList = async () => {
    try {
      await interceptor.axiosGet(
        "get",
        `users/broadcaster/${user?.id}/broadcaster-screen`,
        token
      ).then((response) => {
        if (response.status === 200) {
          setItems(response?.data?.data?.screens || []);
        }
      })
    } catch (error) {
      console.error("Failed to fetch screens:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (screen) => {
    try {
      setLoading(true);
      const response = await interceptor.axiosDelete("DELETE", `users/broadcaster/${user?.id}/broadcaster-screen/${screen.id}`,
        null, token);
      if (response.status === 200) {
        setLoading(false);
        debugger
        socketRef.current.on("connect", () => {
          console.log("Connected to WebSocket server");
          console.log("Room has been joined");
          socketRef.current.emit("join", { room: screen.location_name });
        });
        socketRef.current.emit("server_message", { 'message': { 'room': screen.location_name, 'data': 'status', 'screen_code': screen.location_name, 'broadcaster_id': user?.id, 'token': token, remove_screen: true }, 'room': screen.location_name });

        fetchScreenList();
        toast.success("Package Deleted Successfully");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };
  const handleEdit = (screen) => {
    setScreenId(screen.id);
    setShowDurModal(true);
    form.setValue("name", screen.name);
    form.setValue("location_name", screen.location_name);
    form.setValue("location_address", screen.location_address);
  };

  const sendNotification = async (screen) => {
    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket server");
      console.log("Room has been joined");
      socketRef.current.emit("join", { room: screen.location_name });
    });
    socketRef.current.emit("server_message", { 'message': { 'room': screen.location_name, 'data': 'status', 'screen_code': screen.location_name, 'broadcaster_id': user?.id, 'token': token, screen_status: 1 }, 'room': screen.location_name });
  }

  const endNotification = async (screen) => {
    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket server");
      console.log("Room has been joined");
      socketRef.current.emit("join", { room: screen.location_name });
    });
    socketRef.current.emit("server_message", { 'message': { 'room': screen.location_name, 'data': 'status', 'screen_code': screen.location_name, 'broadcaster_id': user?.id, 'token': token, screen_status: 0 }, 'room': screen.location_name });
  }

  const handleConfirmDelete = (screen) => {
    setScreenId(screen);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowDurModal(false);
    setScreenId(null);
  };

  const handleModalConfirm = () => {
    handleDelete(screenId);
    handleModalClose();
  };



  const handleModalDurSave = () => {
    form.handleSubmit((values) => {
      setShowDurModal(false);
      let modal = values
      if (screenId) { modal = { ...modal, id: screenId } }
      let url = screenId ? `users/broadcaster/${user?.id}/broadcaster-screen/${screenId}` : `users/broadcaster/${user?.id}/broadcaster-screen`;
      try {
        interceptor.axiosPut(screenId ? "PUT" : "Post", url,
          modal, token
        ).then((response) => {
          if (response.status === 200) {
            socketRef.current.on("connect", () => {
              console.log("Connected to WebSocket server");
              console.log("Room has been joined");
              socketRef.current.emit("join", { room: modal.location_name });
            });

            socketRef.current.emit("server_message", { 'message': { 'room': modal.location_name, 'data': 'status', 'is_screen_connected': true }, 'room': modal.location_name });
            fetchScreenList();
            toast.success("Screen Updated Successfully");
          }
        })
      } catch (error) {
        toast.error(error?.message);
        console.error("Update request failed:", error);
      }
    })();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleModalDurSave();
    }
  };

  return (
    <AppLayout>
      <section className="mb-20 sm:mb-24">
        <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
          {t("screenName")}
        </h5>

        <div className="flex flex-wrap items-center justify-start gap-3  sm:my-4 float-right">
          <Button
            size="sm"
            onClick={() => setShowDurModal(true)}
            className="px-8 w-fit right"
          >
            {t("addscreen")}
          </Button>
        </div>
        <div className="clear-both mt-8">
          <div className="mt-5 overflow-x-auto whitespace-nowrap b-1">
            {loading ? (
              <p>Loading...</p>
            ) : !items ? (
              <div className="flex flex-col items-center gap-5 mt-8 md:mt-12">
                <NoUserIcon
                  width={150}
                  height={96}
                  className="w-24 h-16 sm:w-36 sm:h-24"
                />
                <p className="text-lg font-medium text-center">
                  {" "}
                  Aucun enregistrement trouvé
                </p>
                <Button
                  size="sm"
                  className="w-fit px-9"
                  onClick={() => navigate("/admin/add-packages")}
                >
                  {t("addscreen")}
                </Button>
              </div>
            ) : (
              <div className="w-full overflow-auto">
                <Table className="w-full  border border-2 border-light-gray rounded-2xl" style={{ borderRadius: '5px' }}>
                  <TableHeader>
                    <TableRow className="border-none bg-background">
                      <TableHead className="text-sm font-medium">{t("addscreen")}</TableHead>
                      <TableHead className="text-sm font-medium">{t("code")}</TableHead>
                      <TableHead className="text-sm font-medium">{t("address")}</TableHead>
                      <TableHead className="pr-8 text-right">{t("action")}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentItems?.map((screen) => (
                      <TableRow key={screen.id} className="even:bg-background">
                        <TableCell className="text-sm font-normal py-2.5 h-auto">
                          {screen.name}
                        </TableCell>
                        <TableCell className="text-sm font-normal py-2.5 h-auto">
                          {screen.location_name}
                        </TableCell>
                        <TableCell className="text-sm font-normal py-2.5 h-auto">
                          {screen.location_address}
                        </TableCell>
                        <TableCell className="text-right py-2.5 flex items-center justify-end gap-2.5 h-14 pr-5">
                          <button
                            onClick={() => handleEdit(screen)}
                            className="flex items-center justify-center w-6 h-6 rounded-md bg-blue"
                          >
                            <Edit stroke="white" width={14} height={14} />
                          </button>
                          <button
                            onClick={() => handleConfirmDelete(screen)}
                            className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                          >
                            <DeleteIcon stroke="white" width={14} height={14} />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
          <div className="flex mt-6" style={{ justifyContent: 'right' }}>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </div>
        </div>
      </section>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-6 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">Confirm Deletion</h3>
            <p className="mb-4">
              Are you sure you want to remove this Screen?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleModalClose}
                className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleModalConfirm}
                className="px-4 py-2 text-white bg-red-600 rounded bg-red"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showDurModal ? "" : "hidden"
          }`}
      >

        <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
          <div className="flex flex-col gap-5">
            <div className="bg-background rounded-xl sm:p-8 p-5">
              <label className="text-base font-medium">{t("addscreen")}</label>
              <Form {...form}>
                <div className="flex flex-col gap-4 mt-5">
                  <div>
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Input
                              type="text"
                              className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl"
                              placeholder={t("screenName")}
                              name="name"
                              {...field}
                              onKeyPress={handleKeyPress}

                            />
                          </FormControl>
                          <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="location_name"
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Input
                              type="text"
                              className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl"
                              placeholder={t("code")}
                              {...field}
                              onKeyPress={handleKeyPress}

                            />
                          </FormControl>
                          <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="location_address"
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Textarea
                              type="text"
                              className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl"
                              placeholder={t("address")}
                              {...field}
                              onKeyPress={handleKeyPress}

                            ></Textarea>
                          </FormControl>
                          <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="text-right">
                    <Button
                      size="sm"
                      className="w-50 px-12 mr-5 bg-gray-300 text-gray-700"
                      // className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
                      onClick={() => handleModalClose()}
                    >
                      Close
                    </Button>
                    <Button
                      size="sm"
                      className="w-50 px-12"

                      onClick={handleModalDurSave}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>


    </AppLayout>
  );
};

export default AddScreens;
