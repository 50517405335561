import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

// Components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  DeleteIcon,
  Edit,
  NoUserIcon,
  SearchIcon,
  ViewIcon,
} from "../../../assets/icons/Icons";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import AppLayout from "../../../components/applayout/AppLayout";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../hooks/intercepter.js";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import StatusToggle from "./StatusToggle"; // Import StatusToggle
import { useTranslation } from 'react-i18next';


const Users = () => {
  const { t, i18n } = useTranslation("translation");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { token, removeToken } = useToken();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchUsers(currentPage, searchText);
  }, [currentPage, searchText]);

  const fetchUsers = async (page, searchValue) => {
    try {
      setLoading(true);
      const response = await interceptor.axiosGet(
        "get",
        `users?search_text=${searchValue}&page=${page}&per_page=${itemsPerPage}`,
        token
      );
      if (response.status === 200) {
        const { result, pagination } = response.data.data;
        setUsers(result);
        setTotalPages(pagination.total_pages);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchUsers = useCallback(
    debounce((searchValue) => {
      setSearchText(searchValue);
      setCurrentPage(1); // Reset to first page when search text changes
    }, 500),
    []
  );

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const { value } = e.target;
    debouncedFetchUsers(value);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await interceptor.axiosDelete(
        "DELETE",
        `users/${id}`,
        null,
        token
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success("User Deleted Successfully");

        // Update local state to remove the deleted user
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      } else {
        setLoading(false);
        console.error("Delete failed:", response.data);
        toast.error("Failed to delete user. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Delete request failed:", error);
      toast.error("Failed to delete user. Please try again.");
    }
  };

  const handleChangeStatus = async (user) => {
    try {
      setLoading(true);
      const response = await interceptor.axiosPut(
        "PUT",
        `users/status`,
        {
          id: user.id,
          is_active: !user.is_active, // Toggle between true and false
        },
        token
      );

      if (response.status === 200) {
        // Update the user list after status update
        const updatedUsers = users.map((u) =>
          u.id === user.id ? { ...u, is_active: !user.is_active } : u
        );
        setUsers(updatedUsers);
        setLoading(false);
        toast.success("Status Updated Successfully");
      } else {
        setLoading(false);
        console.error("Update failed:", response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
      console.error("Update request failed:", error);
    }
  };

  const handleEdit = (user) => {
    navigate("/admin/edit-user", { state: { user } });
  };

  const handleView = (user) => {
    navigate("/admin/view-user", { state: { user } });
  };

  const handleConfirmDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleConfirmStatus = (user) => {
    setUser(user);
    setShowChangeStatusModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setDeleteId(null);
  };

  const handleModalConfirm = () => {
    console.log(deleteId);
    handleDelete(deleteId);
    handleModalClose();
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1; // +1 because ReactPaginate starts indexing from 0
    setCurrentPage(selectedPage);
  };

  return (
    <AppLayout>
      <section className="mb-20 sm:mb-24">
        <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
          {t('heading')}
        </h5>
        <div className="flex flex-wrap items-center justify-between gap-3 mt-6 sm:mt-8">
          <div className="relative sm:w-[25rem] w-full">
            <Input
              type="text"
              className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] pl-14 pr-5 rounded-xl border-none"
              placeholder="Filter Emails..."
              value={searchText}
              onChange={handleSearch}
            />
            <button
              onClick={() => fetchUsers(currentPage, searchText)}
              className="absolute -translate-y-1/2 top-1/2 left-5"
            >
              <SearchIcon stroke="#242331" width={20} height={21} />
            </button>
          </div>
          <Button
            size="sm"
            onClick={() => navigate("/admin/add-user")}
            className="px-8 w-fit"
          >
             {t('addUser')}
          </Button>
        </div>
        <div className="mt-5 overflow-x-auto whitespace-nowrap">
          {loading ? (
            <p>Loading...</p>
          ) : users?.length === 0 ? (
            <div className="flex flex-col items-center gap-5 mt-8 md:mt-12">
              <NoUserIcon
                width={150}
                height={96}
                className="w-24 h-16 sm:w-36 sm:h-24"
              />
              <p className="text-lg font-medium text-center">
                Aucun utilisateur trouvé. Veuillez ajouter des utilisateurs.
              </p>
              <Button
                size="sm"
                onClick={() => navigate("/admin/add-user")}
                className="w-fit px-9"
              >
                {t('addUser')}
              </Button>
            </div>
          ) : (
            <div className="w-full overflow-auto">
              <Table className="w-full overflow-hidden border border-light-gray rounded-xl">
                <TableHeader>
                  <TableRow className="border-none bg-background">
                    <TableHead className="text-sm font-medium"> {t('name')}</TableHead>
                    <TableHead className="text-sm font-medium"> {t('e-mail')}</TableHead>
                    <TableHead className="text-sm font-medium">
                    {t('status')}
                    </TableHead>
                    <TableHead className="text-sm font-medium"> {t('phone')}</TableHead>
                    <TableHead className="pr-8 text-right"> {t('action')}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {users?.map((user) => (
                    <TableRow key={user?.id} className="even:bg-background">
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user?.first_name} {user?.last_name}
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user?.email}
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        <StatusToggle
                          isActive={user?.is_active}
                          onToggle={() => handleChangeStatus(user)}
                        />
                      </TableCell>
                      <TableCell className="text-sm font-normal py-2.5 h-auto">
                        {user?.phone}
                      </TableCell>
                      <TableCell className="text-right py-2.5 flex items-center justify-end gap-2.5 h-14 pr-5">
                        <button
                          onClick={() => handleView(user)}
                          className="flex items-center justify-center w-6 h-6 text-white rounded-md bg-blue"
                        >
                          <ViewIcon stroke="white" />
                        </button>
                        <button
                          onClick={() => handleEdit(user)}
                          className="flex items-center justify-center w-6 h-6 rounded-md bg-lime-700"
                        >
                          <Edit stroke="white" width={14} height={14} />
                        </button>
                        <button
                          onClick={() => handleConfirmDelete(user?.id)}
                          className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                        >
                          <DeleteIcon stroke="white" width={14} height={14} />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
        <div className="flex justify-center mt-6 mb-12">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            initialPage={currentPage - 1} // ReactPaginate starts indexing from 0
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </div>
      </section>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-6 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">Confirm Deletion</h3>
            <p className="mb-4">Are you sure you want to delete this user?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleModalClose}
                className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleModalConfirm}
                className="px-4 py-2 text-white bg-red-600 rounded bg-red"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {showChangeStatusModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-6 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">
              Confirm Change Status
            </h3>
            <p className="mb-4">
              Are you sure you want to change the status of user?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowChangeStatusModal(false)}
                className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleChangeStatus(user);
                  setShowChangeStatusModal(false);
                }}
                className="px-4 py-2 text-white bg-red-600 rounded bg-red"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default Users;
