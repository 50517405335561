import React, { Fragment } from "react";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../../../../components/ui/carousel";
import {
  QuoteUpIcon,
  SemiCircleLightBlue,
  SemiCircleYellow,
} from "../../../../assets/icons/Icons";

// Assets
import lucas from "../../../../assets/images/lucas.png";
import clemence from "../../../../assets/images/clemence.png";
import jules from "../../../../assets/images/jules.png";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t } = useTranslation("translation");
  return (
    <Fragment>
      <section className="bg-background md:pt-24 md:pb-20 pb-14 pt-20 relative overflow-hidden">
        <QuoteUpIcon
          fill="#242331"
          width={653}
          height={489}
          className="absolute lg:top-0 md:bottom-0 sm:bottom-[6%] bottom-[17%] left-0 lg:w-[40.813rem] lg:h-[30.563rem] md:w-[25rem] md:h-[18.75rem] w-[12.5rem] h-[12.5rem]"
        />

        <div className="md:container mx-auto px-5">
          <div className="relative">
            <SemiCircleYellow
              fill="#D5FC71"
              width={80}
              height={220}
              className="absolute opacity-100 -top-28 right-20 transform rotate-90 md:w-20 w-16 z-[1]"
            />
            <SemiCircleLightBlue
              fill="#23E3EE"
              width={49}
              height={100}
              className="absolute opacity-100 -top-12 right-52 z-[1]"
            />
            <Carousel
              showDots={true}
              opts={{
                align: "start",
              }}
            >
              <CarouselContent>
                <CarouselItem>
                  <div className="flex lg:flex-row flex-col-reverse items-center lg:justify-between justify-start lg:gap-0 sm:gap-10 gap-7">
                    <div className="lg:w-4/12 w-full">
                      <h4 className="md:text-[2.5rem] text-3xl font-playfair font-bold">
                        {t("section6Head1")}
                      </h4>
                      <p className="text-xl font-medium mt-5">
                        {t("section6Detail1")}
                      </p>
                    </div>
                    <div className="lg:w-6/12 w-full">
                      <img
                        className="block object-cover lg:w-auto w-full rounded-xl"
                        src={lucas}
                        width={647}
                        height={650}
                        alt=""
                      />
                    </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="flex lg:flex-row flex-col-reverse items-center lg:justify-between justify-start lg:gap-0 sm:gap-10 gap-7">
                    <div className="lg:w-4/12 w-full">
                      <h4 className="md:text-[2.5rem] text-3xl font-playfair font-bold">
                      {t("section6Head2")}
                      </h4>
                      <p className="text-xl font-medium mt-5">
                      {t("section6Detail2")}
                      </p>
                    </div>
                    <div className="lg:w-6/12 w-full">
                      <img
                        className="block object-cover lg:w-auto w-full rounded-xl"
                        src={clemence}
                        width={647}
                        height={650}
                        alt=""
                      />
                    </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="flex lg:flex-row flex-col-reverse items-center lg:justify-between justify-start lg:gap-0 sm:gap-10 gap-7">
                    <div className="lg:w-4/12 w-full">
                      <h4 className="md:text-[2.5rem] text-3xl font-playfair font-bold">
                      {t("section6Head3")}
                      </h4>
                      <p className="text-xl font-medium mt-5">
                      {t("section6Detail3")}
                      </p>
                    </div>
                    <div className="lg:w-6/12 w-full">
                      <img
                        className="block object-cover lg:w-auto w-full rounded-xl"
                        src={jules}
                        width={647}
                        height={650}
                        alt=""
                      />
                    </div>
                  </div>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Testimonials;
